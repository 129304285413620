import { ReactElement } from 'react';
import {
  AutocompleteArrayInput,
  BulkDeleteButton,
  DatagridConfigurable,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceField,
  SelectArrayInput,
  SimpleForm,
  TextArrayInput,
  TextField,
  TextInput,
  useCanAccess,
} from 'react-admin';
import TruncatedTextField from '../../Components/TruncatedTextField';
import TzDateField from '../../Components/TzDate/TzDateField';
import TzDateInput from '../../Components/TzDate/TzDateInput';
import { createFilterQuery, DEFAULT_LIST_PAGE_PAGINATION_LIMIT } from '../../utils';
import { BILLING_TYPE_CHOICES } from '../Merchant/utils';
import LargePagination from '../../Components/LargePagination';
import { BulkUpdateFormButton } from '@react-admin/ra-form-layout';
import JsonApiHasOneReferenceInput from '../../Components/JsonApiHasOneReferenceInput';
import { BillingItemResource } from '../../Entities/BillingItem';

const filters = (
  canListBillingItemChargeTypes: boolean,
  canListBillingItemPaymentTypes: boolean,
  canListBillingItemStatuses: boolean,
  canListMerchants: boolean,
  canListRegions: boolean,
): ReactElement[] =>
  [
    <TextArrayInput key="id" label="ID" source="equals.id" />,
    canListMerchants && (
      <ReferenceArrayInput
        key="merchants"
        label="Merchants"
        source="merchant.id"
        reference="merchants"
      >
        <AutocompleteArrayInput
          filterToQuery={createFilterQuery(['merchant_name'])}
          label="Merchants"
          optionText="attributes.merchant_name"
        />
      </ReferenceArrayInput>
    ),
    canListMerchants && (
      <SelectArrayInput
        key="billing_types"
        choices={BILLING_TYPE_CHOICES}
        label="Merchant Billing Type"
        source="merchant.billing_type_code"
      />
    ),
    canListMerchants && canListRegions && (
      <ReferenceArrayInput
        key="merchant_region"
        label="Merchant Regions"
        source="merchant.region.id"
        reference="regions"
      >
        <AutocompleteArrayInput
          filterToQuery={createFilterQuery()}
          label="Merchant Regions"
          optionText="attributes.name"
        />
      </ReferenceArrayInput>
    ),
    canListBillingItemChargeTypes && (
      <ReferenceArrayInput
        key="billing_item_charge_types"
        label="Charge Types"
        source="billing_item_charge_type.id"
        reference="billing_item_charge_types"
      >
        <AutocompleteArrayInput label="Charge Types" optionText="attributes.name" />
      </ReferenceArrayInput>
    ),
    canListBillingItemPaymentTypes && (
      <ReferenceArrayInput
        key="billing_item_payment_types"
        label="Payment Types"
        source="billing_item_payment_type.id"
        reference="billing_item_payment_types"
      >
        <AutocompleteArrayInput label="Payment Types" optionText="attributes.name" />
      </ReferenceArrayInput>
    ),
    canListBillingItemStatuses && (
      <ReferenceArrayInput
        key="billing_item_statuses"
        label="Statuses"
        source="billing_item_status.id"
        reference="billing_item_statuses"
      >
        <AutocompleteArrayInput label="Statuses" optionText="attributes.name" />
      </ReferenceArrayInput>
    ),
    <NumberInput key="amount_gte" label="Min Amount" source="greaterOrEqual.amount" />,
    <NumberInput key="amount_lte" label="Max Amount" source="lessOrEqual.amount" />,
    <NumberInput key="clicks_gte" label="Min Clicks" source="greaterOrEqual.clicks" />,
    <NumberInput key="clicks_lte" label="Max Clicks" source="lessOrEqual.clicks" />,
    <NumberInput key="cpc_gte" label="Min CPC" source="greaterOrEqual.cpc" />,
    <NumberInput key="cpc_lte" label="Max CPC" source="lessOrEqual.cpc" />,
    <TextArrayInput key="comments" label="Comments Contain ..." source="contains.comments" />,
    <NumberInput
      key="discount_percentage_gte"
      label="Min Discount Percentage"
      source="greaterOrEqual.discount_percentage"
    />,
    <NumberInput
      key="discount_percentage_lte"
      label="Max Discount Percentage"
      source="lessOrEqual.discount_percentage"
    />,
    <NumberInput
      key="merchant_max_spend_cap_gte"
      label="Min Merchant Spend Cap"
      source="greaterOrEqual.merchant_max_spend_cap"
    />,
    <NumberInput
      key="merchant_max_spend_cap_lte"
      label="Max Merchant Spend Cap"
      source="lessOrEqual.merchant_max_spend_cap"
    />,
    <TzDateInput
      key="month_billed_eq"
      label="Month Billed"
      source="equals.month_billed"
      views={['month', 'year']}
      precision="month"
      translateTimezone
    />,
    <TzDateInput
      key="month_billed_gte"
      label="Earliest Month Billed"
      source="greaterOrEqual.month_billed"
      views={['month', 'year']}
      precision="month"
      translateTimezone
    />,
    <TzDateInput
      key="month_billed_lte"
      label="Latest Month Billed"
      source="lessOrEqual.month_billed"
      views={['month', 'year']}
      precision="month"
      translateTimezone
    />,
    <NumberInput key="netsuite_id" label="NetSuite ID" source="equals.netsuite_id" />,
    <TextArrayInput
      key="netsuite_error_contains"
      label="NetSuite Error Contains ..."
      source="contains.netsuite_last_error"
    />,
  ].filter((f) => typeof f !== 'boolean');

const bulkActions: ReactElement = (
  <>
    <BulkUpdateFormButton mutationMode="pessimistic">
      <SimpleForm>
        <JsonApiHasOneReferenceInput<
          BillingItemResource,
          'billing_item_status',
          'billing_item_statuses'
        >
          label="Status"
          optionText="attributes.name"
          source="relationships.billing_item_status"
          reference="billing_item_statuses"
          nullable={false}
        />
        <TextInput label="Comments" source="attributes.comments" />
      </SimpleForm>
    </BulkUpdateFormButton>
    <BulkDeleteButton mutationMode="pessimistic" />
  </>
);

export default function BillingItemList(): ReactElement {
  const billingItemChargeTypeAccess = useCanAccess({
    resource: 'billing_item_charge_types',
    action: 'list',
  });
  const billingItemWriteAccess = useCanAccess({
    resource: 'billing_items',
    action: 'write',
  });
  const billingItemPaymentTypeAccess = useCanAccess({
    resource: 'billing_item_payment_types',
    action: 'list',
  });
  const billingItemStatusAccess = useCanAccess({
    resource: 'billing_item_statuses',
    action: 'list',
  });
  const merchantAccess = useCanAccess({ resource: 'merchants', action: 'list' });
  const payAccountAccess = useCanAccess({ resource: 'pay_accounts', action: 'list' });
  const regionAccess = useCanAccess({ resource: 'regions', action: 'list' });

  const canWriteBillingItems = billingItemWriteAccess.canAccess ?? false;
  const canListBillingItemChargeTypes = billingItemChargeTypeAccess.canAccess ?? false;
  const canListBillingItemPaymentTypes = billingItemPaymentTypeAccess.canAccess ?? false;
  const canListBillingItemStatuses = billingItemStatusAccess.canAccess ?? false;
  const canListMerchants = merchantAccess.canAccess ?? false;
  const canListPayAccounts = payAccountAccess.canAccess ?? false;
  const canListRegions = regionAccess.canAccess ?? false;

  const prefetch: string[] = [
    canListMerchants && 'merchant',
    canListPayAccounts && 'merchant.pay_account',
    canListBillingItemChargeTypes && 'billing_item_charge_type',
    canListBillingItemStatuses && 'billing_item_status',
    canListBillingItemPaymentTypes && 'billing_item_payment_type',
    canListRegions && 'merchant.region',
  ].filter((i) => typeof i === 'string');

  return (
    <List
      filters={filters(
        canListBillingItemChargeTypes,
        canListBillingItemPaymentTypes,
        canListBillingItemStatuses,
        canListMerchants,
        canListRegions,
      )}
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<LargePagination />}
      perPage={DEFAULT_LIST_PAGE_PAGINATION_LIMIT}
      queryOptions={{ meta: { prefetch } }}
    >
      <DatagridConfigurable
        bulkActionButtons={canWriteBillingItems ? bulkActions : false}
        omit={[
          'attributes.date_created',
          'attributes.discount_percentage',
          'attributes.invoice_allocation_id',
          'attributes.netsuite_id',
          'attributes.netsuite_last_error',
          'attributes.xero_id',
          'attributes.xero_last_error',
        ]}
      >
        <TextField label="ID" source="id" />
        {canListMerchants && (
          <ReferenceField
            label="Merchant"
            source="relationships.merchant.data.id"
            reference="merchants"
            queryOptions={{ meta: { prefetch: ['pay_account', 'region'] } }}
          >
            <TextField source="attributes.merchant_name" />
          </ReferenceField>
        )}
        {canListMerchants && canListRegions && (
          <ReferenceField
            label="Merchant Region"
            source="relationships.merchant.data.id"
            reference="merchants"
            queryOptions={{ meta: { prefetch: ['pay_account', 'region'] } }}
          >
            <ReferenceField
              label="Merchant Region"
              source="relationships.region.data.id"
              reference="regions"
            >
              <TextField source="attributes.name" />
            </ReferenceField>
          </ReferenceField>
        )}
        <TzDateField translateTimezone label="Month Billed" source="attributes.month_billed" />
        {canListBillingItemChargeTypes && (
          <ReferenceField
            label="Charge Type"
            source="relationships.billing_item_charge_type.data.id"
            reference="billing_item_charge_types"
          >
            <TextField source="attributes.name" />
          </ReferenceField>
        )}
        {canListBillingItemPaymentTypes && (
          <ReferenceField
            label="Payment Type"
            source="relationships.billing_item_payment_type.data.id"
            reference="billing_item_payment_types"
          >
            <TextField source="attributes.name" />
          </ReferenceField>
        )}
        {canListBillingItemStatuses && (
          <ReferenceField
            label="Status"
            source="relationships.billing_item_status.data.id"
            reference="billing_item_statuses"
          >
            <TextField source="attributes.name" />
          </ReferenceField>
        )}
        <NumberField
          label="Amount"
          source="attributes.amount"
          options={{ minimumFractionDigits: 2 }}
        />
        <NumberField label="Clicks" source="attributes.clicks" />
        <TruncatedTextField length={50} label="Comments" source="attributes.comments" />
        <NumberField label="CPC" source="attributes.cpc" />
        <TzDateField showTime label="Date Created" source="attributes.date_created" />
        <NumberField label="Discount Percentage" source="attributes.discount_percentage" />
        <TextField label="Invoice Allocation ID" source="attributes.invoice_allocation_id" />
        <NumberField label="Merchant Spend Cap" source="attributes.merchant_max_spend_cap" />
        <TextField label="NetSuite ID" source="attributes.netsuite_id" />
        <TextField label="NetSuite Last Error" source="attributes.netsuite_last_error" />
        <TextField label="Xero ID" source="attributes.xero_id" />
        <TextField label="Xero Last Error" source="attributes.xero_last_error" />
      </DatagridConfigurable>
    </List>
  );
}
