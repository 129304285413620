import { ReactElement } from 'react';
import {
  AutocompleteInput,
  AutocompleteInputProps,
  ReferenceInput,
  ReferenceInputProps,
  useRecordContext,
} from 'react-admin';
import { JsonApiHasOneRelationship, JsonApiResource } from '../DataProvider';
import { get } from 'lodash';
import { match, P } from 'ts-pattern';
import { createFilterQuery } from '../utils';

export interface JsonApiHasOneReferenceInputProps<
  TReferenceName extends keyof JsonApiResource['relationships'],
  TReferenceType extends string,
> {
  source: `relationships.${TReferenceName}`;
  reference: TReferenceType;
  optionText?: AutocompleteInputProps['optionText'];
  label?: string;
  isRequired?: boolean;
  emptyText?: string;
  nullable?: boolean;
  defaultValue?: JsonApiResource['id'];
  format?: (value: JsonApiHasOneRelationship<TReferenceName>) => string | undefined;
  parse?: (value: string) => JsonApiHasOneRelationship<TReferenceType> | undefined;
  referenceInputProps?: Omit<
    ReferenceInputProps,
    keyof JsonApiHasOneReferenceInputProps<TReferenceName, TReferenceType>
  >;
  autocompleteInputProps?: Omit<
    AutocompleteInputProps,
    keyof JsonApiHasOneReferenceInputProps<TReferenceName, TReferenceType>
  >;
  filterFields?: string[];
  validate?: AutocompleteInputProps['validate'];
}

function defaultFormat<T extends string>(value: JsonApiHasOneRelationship<T>): string | undefined {
  return value?.data?.id ?? '';
}

function defaultParse<TReferenceType extends string>(
  nullable: boolean,
  reference: TReferenceType,
): (value?: string) => JsonApiHasOneRelationship<TReferenceType> | undefined {
  return (value?: string) => {
    const v = value ?? '';

    if (v === '' && nullable) {
      return { data: null };
    }

    if (v === '') {
      return undefined;
    }

    return { data: { id: v, type: reference } };
  };
}

export default function JsonApiHasOneReferenceInput<
  TEntity extends JsonApiResource,
  TRelationshipName extends keyof TEntity['relationships'] & string = string,
  TReferenceType extends string = `${TRelationshipName}s`,
>({
  autocompleteInputProps,
  defaultValue,
  emptyText,
  filterFields = ['name'],
  format = defaultFormat,
  isRequired = false,
  label,
  nullable = true,
  optionText,
  reference,
  parse = defaultParse(nullable, reference),
  referenceInputProps,
  source,
  validate,
}: JsonApiHasOneReferenceInputProps<TRelationshipName, TReferenceType>): ReactElement {
  const record = useRecordContext<TEntity>();
  return (
    <ReferenceInput
      label={label}
      source={source}
      reference={reference}
      isRequired={isRequired}
      emptyText={emptyText}
      queryOptions={{ meta: { ensureIncluded: get(record, source)?.data } }}
      {...referenceInputProps}
    >
      <AutocompleteInput
        label={label}
        optionText={optionText}
        format={format}
        parse={parse}
        isRequired={isRequired}
        validate={validate}
        defaultValue={match({ defaultValue, nullable })
          .with({ defaultValue: P.union('', undefined), nullable: false }, () => undefined)
          .with({ defaultValue: P.union('', undefined), nullable: true }, () => ({ data: null }))
          .otherwise(() => ({ data: { id: defaultValue, type: reference } }))}
        filterToQuery={createFilterQuery(filterFields)}
        {...autocompleteInputProps}
      />
    </ReferenceInput>
  );
}
