import { AutocompleteInput, Create, ReferenceInput, required, SimpleForm } from 'react-admin';
import EditToolbar from '../../Components/EditToolbar';
import ExternalCategoryAutocompleteInput from './ExternalCategoryAutocompleteInput';
import { ReactElement } from 'react';
import { createFilterQuery } from '../../utils';

export default function MappingRuleCreate(): ReactElement {
  return (
    <Create>
      <SimpleForm
        defaultValues={{
          relationships: {
            merchant: { data: { type: 'merchants' } },
            category: { data: { type: 'categories' } },
          },
        }}
        toolbar={<EditToolbar />}
      >
        <ReferenceInput
          label="Merchant"
          reference="merchants"
          source="relationships.merchant.data.id"
        >
          <AutocompleteInput
            filterToQuery={createFilterQuery(['merchant_name'])}
            isRequired
            validate={required()}
            label="Merchant"
            optionText="attributes.merchant_name"
          />
        </ReferenceInput>
        <ExternalCategoryAutocompleteInput />
        <ReferenceInput
          filter={{ level: 0 }}
          label="Category"
          reference="categories"
          source="relationships.category.data.id"
        >
          <AutocompleteInput
            filterToQuery={createFilterQuery()}
            isRequired
            validate={required()}
            label="Category"
            optionText="attributes.full_name"
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
