import { useMutation } from '@tanstack/react-query';
import { useGetRecordId, useNotify, useRefresh } from 'react-admin';
import { httpClient } from '../../DataProvider';

export const BILLING_TYPE_CHOICES = [
  { id: 0, name: 'CPC' },
  { id: 1, name: 'CPA' },
];

export const MAIL_CAP_STATUS_CHOICES = [
  { id: 0, name: 'Cap OK' },
  { id: 1, name: '75% Cap' },
  { id: 2, name: '100% Cap' },
];

export const PAYMENT_TYPE_CHOICES = [
  { id: 0, name: 'Credit card' },
  { id: 1, name: 'Auto debit' },
  { id: 2, name: 'Cheque' },
  { id: 3, name: 'Direct debit' },
  { id: 4, name: 'EFT' },
  { id: 5, name: 'Hand written cheque' },
  { id: 6, name: 'Cash' },
  { id: 7, name: 'NI/NM cheques' },
  { id: 8, name: 'eStore receipts' },
  { id: 9, name: 'Prepay' },
  { id: 10, name: 'AMEX' },
];

export const FEED_DISCRIMINATOR_CSV = 'CsvFeed';
export const FEED_DISCRIMINATOR_XML = 'XmlFeed';
export const FEED_DISCRIMINATOR_XML_INDEX = 'XmlIndexFeed';

export const FEED_DISCRIMINATOR_CHOICES = [
  { id: FEED_DISCRIMINATOR_CSV, name: 'CSV' },
  { id: FEED_DISCRIMINATOR_XML, name: 'XML' },
  { id: FEED_DISCRIMINATOR_XML_INDEX, name: 'XML Feed with Index File' },
];

export const FEED_DELIMITER_CHOICES = [
  { id: 9, name: 'Tab (\\t)' },
  { id: 33, name: 'Exclamation mark (!)' },
  { id: 44, name: 'Comma (,)' },
  { id: 59, name: 'Semicolon (;)' },
  { id: 124, name: 'Pipe (|)' },
];

export const FEED_TEXT_QUALIFIER_CHOICES = [
  { id: 1, name: 'Double Quote (")' },
  { id: 2, name: "Single Quote (')" },
  { id: 3, name: 'Two Forward Slashes (//)' },
];

export const FEED_FIELD_TYPE_CHOICES = [
  { id: 0, name: 'Category URL' },
  { id: 1, name: 'Category Page Name' },
  { id: 100, name: 'Product URL' },
  { id: 101, name: 'Unique ID' },
  { id: 102, name: 'Name' },
  { id: 103, name: 'Manufacturer' },
  { id: 104, name: 'MPN' },
  { id: 105, name: 'GTIN' },
  { id: 106, name: 'Category Name' },
  { id: 107, name: 'Current Price' },
  { id: 108, name: 'Regular Price' },
  { id: 109, name: 'Shipping' },
  { id: 110, name: 'Image URL' },
  { id: 111, name: 'Description' },
  { id: 112, name: 'Model' },
  { id: 113, name: 'Is Valid?' },
  { id: 114, name: 'Stock' },
];

export const FEED_FIELD_DISCRIMINATOR_CSV = 'CsvFeedMap';
export const FEED_FIELD_DISCRIMINATOR_XML = 'XmlFeedMap';

export const FEED_FIELD_DISCRIMINATOR_CHOICES = [
  { id: FEED_FIELD_DISCRIMINATOR_CSV, name: 'CSV Column' },
  { id: FEED_FIELD_DISCRIMINATOR_XML, name: 'XML Value' },
];

/**
 * Convert a number to a spreadsheet column name, e.g. 0 -> A, 1 -> B, 26 -> AA, 27 -> AB, etc.
 */
export function intToSpreadsheetColumnName(i: number): string {
  let result = '';
  const radixString = i.toString(26);

  for (let j = 0; j < radixString.length; j++) {
    const charCode = radixString.charAt(j);
    const char = parseInt(charCode, 26);
    const adjusted = char + 65 - (j < radixString.length - 1 ? 1 : 0);
    const newChar = String.fromCharCode(adjusted);

    result += newChar;
  }

  return result;
}

/**
 * Convert a spreadsheet column name to a number, e.g. A -> 0, B -> 1, AA -> 26, AB -> 27, etc.
 */
export function spreadsheetColumnNameToInt(column: string): number {
  let result = 0;

  // shift the unicode value of the characters to the beginning of the unicode table
  // (not 0-based for math reasons)
  for (let i = 0; i < column.length; i++) {
    const char = column.charCodeAt(i) - 65 + 1;
    result += char * 26 ** (column.length - i - 1);
  }

  result -= 1;

  return result;
}

/**
 * Note: Using 77 (BZ) as the max column because that's what the old admin panel used.
 */
export const FEED_FIELD_INDEX_CHOICES = Array.from({ length: 77 }, (_, i) => ({
  id: i,
  name: intToSpreadsheetColumnName(i),
}));

const runAsync = (merchantId: string): Promise<unknown> => {
  const scannerUrl = 'https://scanner.getprice.com.au/scan/shops/';
  return new Promise((resolve, reject) => {
    httpClient(`${scannerUrl}`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: `[${merchantId}]`,
    })
      .then((response) => {
        if (response.status >= 400) {
          throw new Error('Network response was not ok');
        }
        return response.json;
      })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

type UseRunScanMutation = () => {
  handleRunScan: () => Promise<void>;
  isLoading: boolean;
};

export const useRunScanMutation: UseRunScanMutation = () => {
  const recordId = useGetRecordId();
  const mutation = useMutation({ mutationFn: runAsync });
  const notify = useNotify();
  const refresh = useRefresh();

  const handleRunScan = async (): Promise<void> => {
    try {
      const newScanId = await mutation.mutateAsync(recordId.toString());
      notify(`Scan initiated successfully: ${newScanId}`, { type: 'success' });
      refresh();
    } catch (error) {
      notify(`Error initiating scan: ${(error as Error).message}`, { type: 'warning' });
    }
  };

  return {
    handleRunScan,
    isLoading: mutation.isPending,
  };
};
