import {
  fetchUtils,
  HttpError,
  Identifier,
  RaRecord,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { StatusCodes } from 'http-status-codes';
import { isEqual } from 'lodash';
import { defaultHeaders, isJsonApiResourceLink } from './utils';
import { JsonApiResource, JsonApiSingleResponse } from './JsonApiTypes';

export default function update(apiUrl: string, httpClient = fetchUtils.fetchJson) {
  return async <T extends RaRecord<Identifier>>(
    resource: string,
    params: UpdateParams,
  ): Promise<UpdateResult<T & JsonApiResource>> => {
    // Only send changed attributes
    const attributes = Object.entries(params.data.attributes ?? {}).reduce(
      (acc, [key, value]) => {
        const previousAttributes = params.previousData.attributes ?? {};
        if (!isEqual(value, previousAttributes[key])) {
          acc[key] = value;
        }
        return acc;
      },
      {} as Record<string, unknown>,
    );

    const relationships = Object.entries(params.data.relationships ?? {}).reduce(
      (acc, [key, value]) => {
        const previousRelationships = params.previousData.relationships ?? {};
        if (isJsonApiResourceLink(value) && !isEqual(value, previousRelationships[key])) {
          acc[key] = value;
        }

        return acc;
      },
      {} as Record<string, unknown>,
    );

    const payload = {
      data: {
        type: params.data.type ?? resource,
        id: params.id,
        attributes: Object.keys(attributes).length > 0 ? attributes : undefined,
        relationships: Object.keys(relationships).length > 0 ? relationships : undefined,
      },
    };

    const query = new URLSearchParams();

    params.meta?.prefetch?.forEach((prefetch: string) => {
      query.append('include', prefetch);
    });

    const url = `${apiUrl}/${resource}/${params.id}?${query}`;

    try {
      const response = await httpClient(url, {
        method: 'PATCH',
        body: JSON.stringify(payload),
        headers: defaultHeaders,
      });

      const result = response.json as JsonApiSingleResponse;

      if (response.status === StatusCodes.NO_CONTENT) {
        const data = { ...params.previousData, ...params.data } as T & JsonApiResource;
        return { data };
      }

      const data = { ...params.previousData, ...result.data } as T & JsonApiResource;
      return { data };
    } catch (error) {
      throw new HttpError(
        (error as HttpError).body?.errors[0].detail ?? 'Error updating record',
        (error as HttpError).body.status ?? 500,
        (error as HttpError).body,
      );
    }
  };
}
