import { ReactElement } from 'react';
import {
  Configurable,
  Edit,
  Labeled,
  NumberField,
  NumberInput,
  ReferenceField,
  SimpleForm,
  TextField,
  TextInput,
  useCanAccess,
  usePreference,
} from 'react-admin';
import EditToolbar from '../../Components/EditToolbar';
import TzDateField from '../../Components/TzDate/TzDateField';
import TzDateInput from '../../Components/TzDate/TzDateInput';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid2 as Grid,
  InputAdornment,
  Switch,
} from '@mui/material';
import { useWatch } from 'react-hook-form';
import JsonApiHasOneReferenceInput from '../../Components/JsonApiHasOneReferenceInput';
import { BillingItemResource } from '../../Entities/BillingItem';

const fields = {
  id: 'Billing Item ID',
  date_created: 'Date Created',
  'billing_item_charge_type.name': 'Charge Type',
  month_billed: 'Month Billed',
  'billing_item_status.name': 'Status',
  'pay_account.comment': 'Payment Method Comment',
  'merchant.id': 'Merchant',
  merchant_max_spend_cap: 'Spend Cap (AUD)',
  'billing_item_payment_type.name': 'Payment Type',
  'pay_account.payment_method_expiry_date': 'Payment Method Expiry Date',
  amount: 'Amount (AUD)',
  cpc: 'CPC',
  discount_percentage: 'Discount Percentage',
  clicks: 'Clicks',
  comments: 'Comments',
  invoice_allocation_id: 'Invoice Allocation ID',
  netsuite_id: 'NetSuite ID',
  netsuite_last_error: 'Last NetSuite Error',
  xero_id: 'Xero ID',
  xero_last_error: 'Last Xero Error',
};

type FieldName = keyof typeof fields;

const defaultOmit: FieldName[] = [
  'discount_percentage',
  'invoice_allocation_id',
  'netsuite_id',
  'netsuite_last_error',
  'xero_id',
  'xero_last_error',
] as const;

function BillingItemConfigurableForm(): ReactElement {
  const [omit] = usePreference('omit', defaultOmit);
  const amount = useWatch({ name: 'attributes.amount' });
  const discountPercentage = useWatch({ name: 'attributes.discount_percentage' });
  const clicks = useWatch({ name: 'attributes.clicks' });
  const cpc = useWatch({ name: 'attributes.cpc' });

  function includeSectionIfAnyNotOmitted(...fieldNames: FieldName[]) {
    return (section: ReactElement): ReactElement | null =>
      fieldNames.some((fieldName) => !omit.includes(fieldName)) ? section : null;
  }

  function shouldShowField(fieldName: FieldName): boolean {
    return !omit.includes(fieldName);
  }

  return (
    <Grid container spacing={2}>
      {includeSectionIfAnyNotOmitted(
        'id',
        'billing_item_charge_type.name',
        'date_created',
        'month_billed',
        'billing_item_status.name',
        'pay_account.comment',
      )(
        <Grid size={{ xs: 12, xl: 4 }}>
          <Card style={{ height: '100%' }} raised>
            <CardHeader title="General Details" />
            <CardContent>
              <Grid container>
                {shouldShowField('id') && (
                  <Grid size={{ xs: 12, xl: 6 }}>
                    <Labeled>
                      <TextField label={fields['id']} source="id" />
                    </Labeled>
                  </Grid>
                )}
                {shouldShowField('billing_item_charge_type.name') && (
                  <Grid size={{ xs: 12, xl: 6 }}>
                    <JsonApiHasOneReferenceInput<BillingItemResource, 'billing_item_charge_type'>
                      label={fields['billing_item_charge_type.name']}
                      source="relationships.billing_item_charge_type"
                      reference="billing_item_charge_types"
                      optionText="attributes.name"
                    />
                  </Grid>
                )}
                {shouldShowField('date_created') && (
                  <Grid size={{ xs: 12, xl: 6 }}>
                    <Labeled>
                      <TzDateField
                        translateTimezone
                        label={fields['date_created']}
                        source="attributes.date_created"
                      />
                    </Labeled>
                  </Grid>
                )}
                {shouldShowField('month_billed') && (
                  <Grid size={{ xs: 12, xl: 6 }}>
                    <TzDateInput
                      translateTimezone
                      label={fields['month_billed']}
                      source="attributes.month_billed"
                    />
                  </Grid>
                )}
                {shouldShowField('billing_item_status.name') && (
                  <Grid size={{ xs: 12, xl: 6 }}>
                    <Labeled>
                      <ReferenceField
                        label={fields['billing_item_status.name']}
                        source="relationships.billing_item_status.data.id"
                        reference="billing_item_statuses"
                      >
                        <TextField source="attributes.name" />
                      </ReferenceField>
                    </Labeled>
                  </Grid>
                )}
                {shouldShowField('pay_account.comment') && (
                  <Grid size={{ xs: 12, xl: 6 }}>
                    <Labeled>
                      <ReferenceField
                        label={fields['pay_account.comment']}
                        source="relationships.merchant.data.id"
                        reference="merchants"
                        link={false}
                      >
                        <ReferenceField
                          label={fields['pay_account.comment']}
                          source="relationships.pay_account.data.id"
                          reference="pay_accounts"
                        >
                          <TextField source="attributes.comment" />
                        </ReferenceField>
                      </ReferenceField>
                    </Labeled>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>,
      )}
      {includeSectionIfAnyNotOmitted(
        'merchant.id',
        'merchant_max_spend_cap',
        'billing_item_payment_type.name',
        'pay_account.payment_method_expiry_date',
      )(
        <Grid size={{ xs: 12, xl: 4 }}>
          <Card style={{ height: '100%' }} raised>
            <CardHeader title="Merchant Details" />
            <CardContent>
              <Grid container rowSpacing={4}>
                {shouldShowField('merchant.id') && (
                  <Grid size={{ xs: 12, xl: 6 }}>
                    <Labeled>
                      <ReferenceField
                        label={fields['merchant.id']}
                        source="relationships.merchant.data.id"
                        reference="merchants"
                      >
                        <TextField source="attributes.merchant_name" />
                      </ReferenceField>
                    </Labeled>
                  </Grid>
                )}
                {shouldShowField('merchant_max_spend_cap') && (
                  <Grid size={{ xs: 12, xl: 6 }}>
                    <Labeled>
                      <NumberField
                        label={fields['merchant_max_spend_cap']}
                        source="attributes.merchant_max_spend_cap"
                        options={{ style: 'currency', currency: 'AUD' }}
                      />
                    </Labeled>
                  </Grid>
                )}
                {shouldShowField('billing_item_payment_type.name') && (
                  <Grid size={{ xs: 12, xl: 6 }}>
                    <Labeled>
                      <ReferenceField
                        label={fields['billing_item_payment_type.name']}
                        source="relationships.billing_item_payment_type.data.id"
                        reference="billing_item_payment_types"
                      >
                        <TextField source="attributes.name" />
                      </ReferenceField>
                    </Labeled>
                  </Grid>
                )}
                {shouldShowField('pay_account.payment_method_expiry_date') && (
                  <Grid size={{ xs: 12, xl: 6 }}>
                    <Labeled>
                      <ReferenceField
                        label={fields['pay_account.payment_method_expiry_date']}
                        source="relationships.merchant.data.id"
                        reference="merchants"
                        link={false}
                      >
                        <ReferenceField
                          label={fields['pay_account.payment_method_expiry_date']}
                          source="relationships.pay_account.data.id"
                          reference="pay_accounts"
                        >
                          <TzDateField
                            label={fields['pay_account.payment_method_expiry_date']}
                            source="attributes.payment_method_expiry_date"
                          />
                        </ReferenceField>
                      </ReferenceField>
                    </Labeled>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>,
      )}
      {includeSectionIfAnyNotOmitted(
        'amount',
        'discount_percentage',
        'cpc',
        'clicks',
      )(
        <Grid size={{ xs: 12, xl: 4 }}>
          <Card style={{ height: '100%' }} raised>
            <CardHeader title="Spend Details" />
            <CardContent>
              <Grid container columnSpacing={2}>
                {shouldShowField('amount') && (
                  <Grid size={{ xs: 12, xl: 6 }}>
                    <NumberInput
                      label={fields['amount']}
                      source="attributes.amount"
                      format={(value) => value?.toFixed(2)}
                      step={0.01}
                      slotProps={{
                        input: {
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        },
                      }}
                    />
                  </Grid>
                )}
                {shouldShowField('discount_percentage') && (
                  <Grid size={{ xs: 12, xl: 6 }}>
                    <NumberInput
                      label={fields['discount_percentage']}
                      source="attributes.discount_percentage"
                      step={1}
                      slotProps={{
                        input: {
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        },
                      }}
                      helperText={
                        discountPercentage &&
                        amount &&
                        `Discounted amount: $${(amount * (1 - discountPercentage / 100)).toFixed(2)}`
                      }
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container columnSpacing={2}>
                {shouldShowField('cpc') && (
                  <Grid size={{ xs: 12, xl: 6 }}>
                    <NumberInput label={fields['cpc']} source="attributes.cpc" step={1} />
                  </Grid>
                )}
                {shouldShowField('clicks') && (
                  <Grid size={{ xs: 12, xl: 6 }}>
                    <NumberInput
                      label={fields['clicks']}
                      source="attributes.clicks"
                      step={1}
                      helperText={`Guideline amount (clicks * CPC): $${((clicks * cpc) / 100).toFixed(2)}`}
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>,
      )}
      {includeSectionIfAnyNotOmitted('comments')(
        <Grid size={{ xs: 12, xl: 8 }}>
          <Card style={{ height: '100%' }} raised>
            <CardHeader title={fields['comments']} />
            <CardContent>
              <Grid container>
                {shouldShowField('comments') && (
                  <Grid size={12}>
                    <TextInput label={false} source="attributes.comments" multiline fullWidth />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>,
      )}
      {includeSectionIfAnyNotOmitted(
        'invoice_allocation_id',
        'netsuite_id',
        'netsuite_last_error',
        'xero_id',
        'xero_last_error',
      )(
        <Grid size={{ xs: 12, xl: 4 }}>
          <Card style={{ height: '100%' }} raised>
            <CardHeader title="Integration Details" />
            <CardContent>
              <Grid container>
                {shouldShowField('invoice_allocation_id') && (
                  <Grid size={12}>
                    <Labeled>
                      <TextField
                        emptyText="Not Set"
                        label={fields['invoice_allocation_id']}
                        source="attributes.invoice_allocation_id"
                      />
                    </Labeled>
                  </Grid>
                )}
                {shouldShowField('netsuite_id') && (
                  <Grid size={12}>
                    <TextInput label={fields['netsuite_id']} source="attributes.netsuite_id" />
                  </Grid>
                )}
                {shouldShowField('netsuite_last_error') && (
                  <Grid size={12}>
                    <Labeled>
                      <TextField
                        emptyText="No Errors"
                        label={fields['netsuite_last_error']}
                        source="attributes.netsuite_last_error"
                      />
                    </Labeled>
                  </Grid>
                )}
                {shouldShowField('xero_id') && (
                  <Grid size={12}>
                    <TextInput label={fields['xero_id']} source="attributes.xero_id" />
                  </Grid>
                )}
                {shouldShowField('xero_last_error') && (
                  <Grid size={12}>
                    <Labeled>
                      <TextField
                        emptyText="No Errors"
                        label={fields['xero_last_error']}
                        source="attributes.xero_last_error"
                      />
                    </Labeled>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>,
      )}
    </Grid>
  );
}

function BillingItemsFormEditor(): ReactElement {
  const [omit, setOmit] = usePreference<FieldName[]>('omit', defaultOmit);

  return (
    <>
      {Object.entries(fields).map(([field, label]) => (
        <Box key={field}>
          <Switch
            checked={!omit.includes(field as FieldName)}
            onChange={(event) =>
              setOmit(
                event.target.checked
                  ? (omit.filter((omitField) => omitField !== field) as FieldName[])
                  : (Array.from(new Set([...omit, field])) as FieldName[]),
              )
            }
          />
          <span>{label}</span>
        </Box>
      ))}
      <Button onClick={() => setOmit(defaultOmit)}>Reset</Button>
      <Button onClick={() => setOmit([])}>Show All</Button>
      <Button onClick={() => setOmit(Object.keys(fields) as FieldName[])}>Hide All</Button>
    </>
  );
}

export default function BillingItemsEdit({
  preferenceKey = 'billing_items.form',
}: {
  preferenceKey: string;
}): ReactElement {
  const merchantReadAccess = useCanAccess({ resource: 'merchants', action: 'read' });
  const payAccountReadAccess = useCanAccess({ resource: 'pay_accounts', action: 'read' });
  const billingItemChargeTypeReadAccess = useCanAccess({
    resource: 'billing_item_charge_types',
    action: 'read',
  });
  const billingItemPaymentTypeReadAccess = useCanAccess({
    resource: 'billing_item_payment_types',
    action: 'read',
  });
  const billingItemStatusReadAccess = useCanAccess({
    resource: 'billing_item_statuses',
    action: 'read',
  });

  const prefetch: string[] = [
    merchantReadAccess.canAccess && 'merchant',
    payAccountReadAccess.canAccess && 'merchant.pay_account',
    billingItemChargeTypeReadAccess.canAccess && 'billing_item_charge_type',
    billingItemStatusReadAccess.canAccess && 'billing_item_status',
    billingItemPaymentTypeReadAccess.canAccess && 'billing_item_payment_type',
  ].filter((i) => typeof i === 'string');

  return (
    <Edit queryOptions={{ meta: { prefetch } }}>
      <Configurable
        editor={<BillingItemsFormEditor />}
        preferenceKey={preferenceKey}
        sx={{ margin: '2px', width: 'calc(100% - 4px)' }}
      >
        <SimpleForm toolbar={<EditToolbar />}>
          <BillingItemConfigurableForm />
        </SimpleForm>
      </Configurable>
    </Edit>
  );
}
