import { DateTime } from 'luxon';

/**
 * The timezone of the server. This is used to convert timestamps from the API
 * to the user's local time. Defaults to Australia/Sydney as that is the
 * timezone our servers operate in. This can be overridden by setting the
 * `SERVER_TIMEZONE` environment variable at build time.
 *
 * @constant {string} SERVER_TIMEZONE
 * @default 'Australia/Sydney'
 */
export const SERVER_TIMEZONE = process.env.SERVER_TIMEZONE ?? 'Australia/Sydney';

/**
 * The timezone of the user's browser.
 * @constant {string} LOCAL_TIMEZONE
 */
export const LOCAL_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * The available timezones for the user to select from. These must all be
 * either IANA timezones or the special value 'utc' for cross-compatibility
 * between the Intl API, luxon (used for timezone conversion and formatting TZ
 * strings), and the date-fns library (used for formatting dates and times in
 * input and display components).
 */
export const validTimezones = [
  { id: LOCAL_TIMEZONE, label: `Browser default (${LOCAL_TIMEZONE.replace(/_/g, ' ')})` },
  { id: SERVER_TIMEZONE, label: `System timezone (${SERVER_TIMEZONE.replace(/_/g, ' ')})` },
  { id: 'utc', label: 'UTC' },
  ...Intl.supportedValuesOf('timeZone')
    .map((tz) => ({ id: tz, label: tz.replace(/_/g, ' ') }))
    .filter((tz) => tz.id !== SERVER_TIMEZONE)
    .filter((tz) => tz.id !== LOCAL_TIMEZONE),
];

/**
 * The available date formats for the user to select from. These formats must
 * be compatible with the date-fns library.
 *
 * @see https://date-fns.org/v4.1.0/docs/format
 */
export const supportedDateFormats = [
  { id: 'dd/MM/y', label: 'AU/UK Format' },
  { id: 'MM/dd/y', label: 'US Format' },
  { id: 'd. M. y', label: 'CZ/SK Format' },
  { id: 'y‑MM‑dd', label: 'ISO-8601 Date' },
];

/**
 * The available time formats for the user to select from. These formats must
 * be compatible with the date-fns library.
 *
 * @see https://date-fns.org/v4.1.0/docs/format
 */
export const supportedTimeFormats = [
  { id: 'HH:mm:ss', label: '24-hour' },
  { id: 'h:mm:ss a', label: '12-hour' },
];

/**
 * The available timezone formats for the user to select from. These formats
 * must be compatible with the luxon library. The reason why we don't just use
 * the date-fns to format everything is that the date-fns library currently
 * does not support outputting anything beyond ISO offsets and GMT offsets. As
 * such, we're using luxon to format the timezone strings (in addition to doing
 * timezone conversions).
 *
 * @see https://moment.github.io/luxon/#/formatting?id=table-of-tokens
 */
export const supportedTimezoneFormats = [
  { id: 'ZZZZ', label: 'Short Timezone Name' },
  { id: 'ZZZZZ', label: 'Full Timezone Name' },
  { id: 'ZZ', label: 'UTC Offset' },
  { id: 'z', label: 'IANA Timezone Name' },
  { id: '', label: 'Not shown' },
];

/**
 * Sets the precision of a DateTime object to the given value. For example, if
 * the precision is set to 'day', then the time will be truncated to the start
 * of the day (e.g. 14:30:15.123 -> 00:00:00.000).
 */
export function setPrecision(time: DateTime, precision?: string): DateTime {
  switch (precision) {
    case 'second':
      return time.set({ millisecond: 0 });
    case 'minute':
      return time.set({ millisecond: 0, second: 0 });
    case 'hour':
      return time.set({ millisecond: 0, second: 0, minute: 0 });
    case 'day':
      return time.set({ millisecond: 0, second: 0, minute: 0, hour: 0 });
    case 'month':
      return time.set({ millisecond: 0, second: 0, minute: 0, hour: 0, day: 1 });
    case 'year':
      return time.set({ millisecond: 0, second: 0, minute: 0, hour: 0, day: 1, month: 1 });
    case 'millisecond':
    default:
      return time;
  }
}

export interface FormatOptions {
  userFormatFn?: (value: string) => string;
  translateTimezone?: boolean;
  timezone?: string;
  precision?: string;
}

/**
 * Converts an ISO-8601 string (without timezone) from the server into a
 * JS Date object for form state.
 *
 * This function accounts for the `translateTimezone` flag:
 *
 * A. If `translateTimezone` is **false** (or undefined):
 *    1. Assume the input string is in `SERVER_TIMEZONE`.
 *       - Example: `"2025-01-01T00:00:00.000"` is in `Australia/Sydney` (UTC+11).
 *    2. Convert to a Luxon DateTime object in `SERVER_TIMEZONE`.
 *       - Example: `DateTime 2025-01-01T00:00:00+11:00`.
 *    3. Convert to a JS Date, which adjusts to the user's system timezone.
 *       - Example: If system timezone is `America/Chicago` (UTC-6), the
 *         JS Date would be `Date 2024-12-31T07:00:00-06:00`.
 *
 * B. If `translateTimezone` is **true**:
 *    1. Assume the input string is in `SERVER_TIMEZONE`.
 *    2. Convert to a Luxon DateTime object in `SERVER_TIMEZONE`.
 *       - Example: `DateTime 2025-01-01T00:00:00+11:00`.
 *    3. Convert to `timezone`, **without shifting time**.
 *       - Example: If `timezone = America/Chicago` (UTC-6), the resulting
 *         DateTime would be `2025-01-01T00:00:00-06:00`.
 *    4. Convert to a JS Date, preserving the displayed time.
 *       - Example: `Date 2025-01-01T00:00:00-06:00` (same clock time, but now
 *         in user's preferred timezone).
 */
export function format({
  userFormatFn,
  translateTimezone,
  timezone,
  precision,
}: FormatOptions = {}) {
  return function (formState: unknown): Date | null {
    let timeString: string;

    if (typeof formState === 'string') {
      timeString = formState;
    } else if (formState instanceof Date) {
      timeString = formState.toISOString();
    } else {
      return null;
    }

    const value = userFormatFn ? userFormatFn(timeString) : timeString;

    if (!value) {
      return null;
    }

    // A1/B1: Assume the input string is in `SERVER_TIMEZONE`.
    // A2/B2: Convert to a Luxon DateTime object in `SERVER_TIMEZONE`.
    let time = DateTime.fromISO(value, { zone: SERVER_TIMEZONE });

    if (translateTimezone) {
      // B3: Convert to `timezone`, **without shifting time**.
      time = time.setZone(timezone ?? LOCAL_TIMEZONE, { keepLocalTime: true });
    }

    // After all timezone conversions (order is important here), truncate the
    // time to the desired precision.
    time = setPrecision(time, precision);

    // A3/B4: Convert to a JS Date (B4: while preserving the displayed time).
    return time.toJSDate();
  };
}

export interface ParseOptions {
  userParseFn?: (value?: string | null) => string;
  translateTimezone?: boolean;
  precision?: string;
}

/**
 * Converts a JS Date object from form state back into an ISO-8601
 * string (without timezone) for the API.
 *
 * This function accounts for the `translateTimezone` flag:
 *
 * A. If `translateTimezone` is **false** (or undefined):
 *    1. Receive a JS Date object, which contains system timezone info.
 *       - Example: `Date 2024-12-31T07:00:00-06:00` (system TZ = UTC-6).
 *    2. Convert to a Luxon DateTime object, keeping the JS Date's timezone.
 *       - Example: `DateTime 2024-12-31T07:00:00-06:00`.
 *    3. Convert to an ISO string **without timezone**.
 *       - Example: `"2025-01-01T00:00:00.000"` (implicitly in SERVER_TIMEZONE).
 *
 * B. If `translateTimezone` is **true**:
 *    1. Receive a JS Date object, which contains system timezone info.
 *       - Example: `Date 2025-01-01T00:00:00-06:00`.
 *    2. Convert to a Luxon DateTime object, preserving the JS Date's timezone.
 *       - Example: `DateTime 2025-01-01T00:00:00-06:00`.
 *    3. Convert to `SERVER_TIMEZONE`, **shifting time by changing only the
 *       timezone**.
 *       - Example: If `SERVER_TIMEZONE = Australia/Sydney` (UTC+11), then the
 *         resulting DateTime is `2025-01-01T00:00:00+11:00` (same local time,
 *         new TZ).
 *    4. Convert to an ISO string **without timezone**.
 *       - Example: `"2025-01-01T00:00:00.000"` (implicitly in SERVER_TIMEZONE).
 */
export function parse({ userParseFn, translateTimezone, precision }: ParseOptions = {}) {
  return function (htmlValue: Date | null): unknown {
    if (!htmlValue) {
      return userParseFn ? userParseFn(null) : null;
    }

    // A1/B1: Receive a JS Date object, which contains system timezone info.
    // A2/B2: Convert to a Luxon DateTime object, keeping the JS Date's timezone.
    let time = DateTime.fromJSDate(htmlValue);

    if (translateTimezone) {
      // B3: Convert to `SERVER_TIMEZONE`, **shifting time by changing only the timezone**.
      time = time.setZone(SERVER_TIMEZONE, { keepLocalTime: true });
    }

    time = time.setZone(SERVER_TIMEZONE);

    // After all timezone conversions (order is important here), truncate the
    // time to the desired precision.
    time = setPrecision(time, precision);

    // A3/B4: Convert to an ISO string **without timezone**.
    const isoDate = time.toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS");

    return userParseFn ? userParseFn(isoDate) : isoDate;
  };
}
