import { useAuthProvider, useRefresh, useStore } from 'react-admin';
import { Checkbox, Grid2 as Grid } from '@mui/material';
import { ReactElement, useEffect, useMemo } from 'react';
import { AuthProvider } from '../DataProvider/AuthProvider';

export default function AssumedPermissionsPreferencesEditor(): ReactElement {
  const authProvider = useAuthProvider<typeof AuthProvider>();
  const [assumed, setAssumed] = useStore<string[] | undefined>(
    'preferences.assumedPermissions',
    undefined,
  );
  const refresh = useRefresh();

  const given: string[] = useMemo(() => {
    const permissions = localStorage.getItem('permissions');
    return permissions ? JSON.parse(permissions) : [];
  }, []);

  useEffect(() => {
    async function fetchPermissions(): Promise<void> {
      if (!authProvider) {
        return;
      }

      if (assumed === undefined && given.length > 0) {
        setAssumed(given);
        return;
      }

      // Find any revoked permissions and remove them from the assumed permissions
      const revoked = assumed?.filter((p: string) => !given.includes(p)) ?? [];

      if (revoked.length > 0) {
        setAssumed(assumed?.filter((p: string) => !revoked.includes(p)));
      }
    }

    fetchPermissions();
  }, [authProvider, assumed, setAssumed, given]);

  useEffect(refresh, [assumed, refresh]);

  function renderCheckbox(p: string): ReactElement {
    return (
      <Grid key={p} size={{ xs: 12, xl: 4 }}>
        <Checkbox
          checked={assumed?.includes(p) ?? false}
          onChange={(e) => {
            if (e.target.checked) {
              setAssumed([...(assumed ?? []), p]);
            } else {
              setAssumed(assumed?.filter((ap: string) => ap !== p));
            }
          }}
        />
        {p}
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid container size={12}>
        {given.filter((p) => p === 'admin:api').map(renderCheckbox)}
      </Grid>
      <Grid container size={{ xs: 12, xl: 6 }}>
        <Grid size={12}>
          <b>Read Permissions</b>
        </Grid>
        {given.filter((p) => p.startsWith('read')).map(renderCheckbox)}
      </Grid>
      <Grid container size={{ xs: 12, xl: 6 }}>
        <Grid size={12}>
          <b>Write Permissions</b>
        </Grid>
        {given.filter((p) => p.startsWith('write')).map(renderCheckbox)}
      </Grid>
    </Grid>
  );
}
