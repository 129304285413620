import { Card, CardContent, Grid2 as Grid } from '@mui/material';
import { ReactElement } from 'react';
import { TabbedForm, TextInput, maxLength, email, TabbedFormViewProps } from 'react-admin';

export default function ContactInfoTab(props: TabbedFormViewProps): ReactElement {
  return (
    <TabbedForm.Tab label="Contact Info" {...props}>
      <Grid container sx={{ width: '100%' }} spacing={2}>
        <Grid size={{ xs: 12, xl: 3 }}>
          <Card raised>
            <CardContent>
              <TextInput
                label="Primary Contact"
                source="attributes.primary_contact"
                validate={maxLength(100)}
              />
              <TextInput
                label="Phone Number"
                source="attributes.phone_number"
                validate={maxLength(100)}
              />
              <TextInput
                label="Email"
                source="attributes.email"
                validate={[maxLength(100), email()]}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid size={{ xs: 12, xl: 3 }}>
          <Card raised>
            <CardContent>
              <TextInput
                label="Secondary Contact"
                source="attributes.secondary_contact"
                validate={maxLength(255)}
              />
              <TextInput
                label="Secondary Phone Number"
                source="attributes.secondary_phone_number"
                validate={maxLength(50)}
              />
              <TextInput
                label="Secondary Email"
                source="attributes.secondary_email"
                validate={[maxLength(255), email()]}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid size={{ xs: 12, xl: 3 }}>
          <Card raised>
            <CardContent>
              <TextInput
                label="Finance Phone Number"
                source="attributes.finance_phone_number"
                validate={maxLength(50)}
              />
              <TextInput
                label="Finance Email"
                source="attributes.finance_email"
                validate={[maxLength(50), email()]}
              />
              <TextInput
                label="Marketing Phone Number"
                source="attributes.marketing_phone_number"
                validate={maxLength(50)}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid size={{ xs: 12, xl: 3 }}>
          <Card raised>
            <CardContent>
              <TextInput
                label="Agency Name"
                source="attributes.agency_name"
                validate={maxLength(200)}
              />
              <TextInput
                label="Agency Phone Number"
                source="attributes.agency_phone_number"
                validate={maxLength(50)}
              />
              <TextInput
                label="Agency Email"
                source="attributes.agency_email"
                validate={[maxLength(100), email()]}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </TabbedForm.Tab>
  );
}
