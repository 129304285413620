import { Identifier, RaRecord, SortPayload } from 'react-admin';
import { get, has } from 'lodash';
import { JsonApiResource } from './JsonApiTypes';
import { match } from 'ts-pattern';

export function createSort(sort: SortPayload): string {
  const sortField = match(sort.field)
    .when(
      (field) => field.match(/^attributes\./),
      (field) => field.replace(/^attributes\./, ''),
    )
    .when(
      (field) => field.match(/^relationships\./),
      (field) => field.replace(/^relationships\./, '').replace('.data.', '.'),
    )
    .otherwise((field) => field);

  const sortDirection = sort.order === 'ASC' ? '' : '-';

  return `${sortDirection}${sortField}`;
}

export const defaultHeaders = new Headers({
  Accept: 'application/vnd.api+json',
  'Content-Type': 'application/vnd.api+json',
});

export function isJsonApiResource<T extends Partial<RaRecord<Identifier>>>(
  data: T | undefined,
): data is T & JsonApiResource {
  return Boolean((data as (JsonApiResource & T) | undefined)?.type);
}

export function isJsonApiResourceLink(
  value: unknown,
): value is { data: { type: string; id: string } } | { data: null } {
  // If `value.data` is _explicitly_ `null`, it means the relationship is being
  // reset. In this case, we should consider it a valid link.
  const isRelationshipReset = has(value, 'data') && get(value, 'data', undefined) === null;

  // NOTE: using get + `typeof` here to ensure that the `type` and `id`
  // properties are present and are strings. Previously, we were using
  // `has(value, 'data.type')` and `has(value, 'data.id')`, but this would
  // return `true` if the properties were present but not defined, e.g.
  // `{ data: { type: undefined, id: undefined } }`. This was not the intended
  // behavior, so we've switched to using `typeof` to ensure that these
  // properties are strings as expected.
  const hasValidType =
    typeof get(value, 'data.type') === 'string' && get(value, 'data.type') !== '';

  const hasValidId = typeof get(value, 'data.id') === 'string' && get(value, 'data.id') !== '';

  return isRelationshipReset || (hasValidType && hasValidId);
}

export function isJsonApiResourceLinkForGetMany(
  value: unknown,
): value is { type: string; id: string } {
  // NOTE: using get + `typeof` here to ensure that the `type` and `id`
  // properties are present and are strings. Previously, we were using
  // `has(value, 'type')` and `has(value, 'id')`, but this would
  // return `true` if the properties were present but not defined, e.g.
  // `{ data: { type: undefined, id: undefined } }`. This was not the intended
  // behavior, so we've switched to using `typeof` to ensure that these
  // properties are strings as expected.
  const hasValidType = typeof get(value, 'type') === 'string' && get(value, 'type') !== '';

  const hasValidId = typeof get(value, 'id') === 'string' && get(value, 'id') !== '';

  return hasValidType && hasValidId;
}

export const transformIds = (values: unknown[]): Identifier[] => {
  return values
    .map((value) => {
      if (isJsonApiResourceLink(value)) {
        return value?.data?.id ?? '';
      }

      if (isJsonApiResourceLinkForGetMany(value)) {
        return value?.id ?? '';
      }

      if (typeof value === 'string' || typeof value === 'number') {
        return value;
      }

      throw new Error('Invalid ID format');
    })
    .filter((id) => id !== '')
    .filter((id, index, self) => self.indexOf(id) === index);
};

export function deriveFilterFromTarget(target: string): string {
  if (target.match(/relationships\.\w+\.data\.id/)) {
    return target.replace('relationships.', '').replace('.data.id', '.id');
  }

  if (target.match(/attributes\.(\w+)/)) {
    return target.replace('attributes.', '');
  }

  return target;
}
