import { fetchUtils, Identifier, RaRecord, UpdateManyParams, UpdateManyResult } from 'react-admin';
import { JsonApiResource } from './JsonApiTypes';
import type { AuthProvider } from './AuthProvider';
import getMany from './getMany';
import { isJsonApiResourceLink } from './utils';

export default function updateMany(
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
  canAccess: typeof AuthProvider.canAccess = async () => true,
) {
  const endpoint = new URL(apiUrl);
  endpoint.pathname = endpoint.pathname + '/operations';
  endpoint.pathname = endpoint.pathname.replace(/\/+/g, '/');

  const getManyFn = getMany(apiUrl, httpClient);

  return async <T extends RaRecord<Identifier>>(
    resource: string,
    params: UpdateManyParams<T>,
  ): Promise<UpdateManyResult<T & JsonApiResource>> => {
    if (!(await canAccess({ resource, action: 'write' }))) {
      throw new Error('Insufficient permissions');
    }

    const ops = [];
    for (const id of params.ids) {
      if (
        params.data.attributes &&
        Object.values(params.data.attributes).some((v) => v !== undefined)
      ) {
        const attributes = Object.fromEntries(
          Object.entries(params.data.attributes).filter(([, v]) => v !== undefined),
        );
        ops.push({
          op: 'update',
          data: { id, type: resource, attributes },
        });
      }

      if (params.data.relationships) {
        for (const [key, relationshipData] of Object.entries(params.data.relationships)) {
          if (isJsonApiResourceLink(relationshipData)) {
            ops.push({
              op: 'update',
              ref: { id, type: resource, relationship: key },
              data: relationshipData.data,
            });
          }
        }
      }
    }

    if (ops.length > 0) {
      const payload = JSON.stringify({ 'atomic:operations': ops });

      const results = await httpClient(`${apiUrl}/operations`, {
        method: 'POST',
        body: payload,
        headers: new Headers({
          'Content-Type': 'application/vnd.api+json; ext="https://jsonapi.org/ext/atomic"',
          Accept: 'application/vnd.api+json; ext="https://jsonapi.org/ext/atomic"',
        }),
      });

      if (results?.json?.errors) {
        throw new Error('Error updating one more more records');
      }
    }

    const updatedData = await getManyFn<T & JsonApiResource>(resource, { ids: params.ids });

    return updatedData as unknown as UpdateManyResult<T & JsonApiResource>;
  };
}
