import { DateInput, DateInputProps } from '@react-admin/ra-form-layout';
import { Configurable, usePreference } from 'react-admin';
import TzDatePreferencesEditor from './TzDatePreferencesEditor';
import { format, LOCAL_TIMEZONE, parse, supportedDateFormats } from './utils';
import { ReactElement } from 'react';

type TzDateInputImplProps = DateInputProps & {
  translateTimezone?: boolean;
  precision?: 'millisecond' | 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
};

function TzDateInputImpl({
  translateTimezone,
  precision,
  format: userFormatFn,
  parse: userParseFn,
  ...dateInputProps
}: TzDateInputImplProps): ReactElement {
  const [dateFormat] = usePreference('format.date', supportedDateFormats[0].id);
  const [timezone] = usePreference('timezone', LOCAL_TIMEZONE);

  const formatFn = format({ userFormatFn, translateTimezone, timezone, precision });
  const parseFn = parse({ userParseFn, translateTimezone, precision });

  return <DateInput mask={dateFormat} {...dateInputProps} format={formatFn} parse={parseFn} />;
}

export type TzDateInputProps = TzDateInputImplProps & { preferenceKey?: string };

/**
 * Creates a timezone-aware DateInput component. This component wraps
 * React-Admin's `<DateInput />` component, but overloads the input's `format`
 * and `parse` functions to achieve any required timezone translations.
 *
 * This does mean that there are some caveats to the `format` and `parse` props
 * on this component to consider above those of the base React-Admin functions.
 *
 * On the `format` function, the value passed to the user-supplied `format`
 * function will be the in-memory form state as usual, but it should always
 * return an empty string (if the value is empty) or a fully-formatted ISO-8601
 * string. This string will be translated to the user's local timezone in the
 * form if `translateTimezone` is set.
 *
 * For `parse`, the value passed to the user-supplied `parse` function will be
 * an ISO-8601 datetime without timezone information, or null for no value. The
 * function should return the new form state value as usual. This date will be
 * translated to the server's timezone if `translateTimezone` is set.
 */
export default function TzDateInput({
  preferenceKey = 'tzDatePreferences',
  ...rest
}: TzDateInputProps): ReactElement {
  return (
    <Configurable
      editor={<TzDatePreferencesEditor showTimezone={false} />}
      preferenceKey={preferenceKey}
      sx={{ width: '100%' }}
    >
      <TzDateInputImpl {...rest} />
    </Configurable>
  );
}
