import { DataProvider as RADataProvider } from 'react-admin';
import { AuthProvider, httpClient } from './AuthProvider';
import getList from './getList';
import getOne from './getOne';
import getMany from './getMany';
import getManyReference from './getManyReference';
import create from './create';
import update from './update';
import updateMany from './updateMany';
import deleteFn from './delete';
import deleteMany from './deleteMany';

export * from './JsonApiTypes';
export { AuthProvider, httpClient } from './AuthProvider';

export const DataProvider = (
  apiUrl: string,
  authProvider: typeof AuthProvider,
): RADataProvider => ({
  getList: getList(apiUrl, httpClient, getOne(apiUrl, httpClient)),
  getOne: getOne(apiUrl, httpClient),
  getMany: getMany(apiUrl, httpClient),
  getManyReference: getManyReference(apiUrl, httpClient),
  create: create(apiUrl, httpClient),
  update: update(apiUrl, httpClient),
  updateMany: updateMany(apiUrl, httpClient, authProvider.canAccess),
  delete: deleteFn(apiUrl, httpClient),
  deleteMany: deleteMany(apiUrl, httpClient, authProvider.canAccess),
  supportAbortSignal: true,
});
