import { DeleteManyParams, DeleteManyResult, fetchUtils, Identifier, RaRecord } from 'react-admin';
import type { AuthProvider } from './AuthProvider';

export default function deleteMany(
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
  canAccess: typeof AuthProvider.canAccess = async () => true,
) {
  return async <T extends RaRecord<Identifier>>(
    resource: string,
    params: DeleteManyParams<T>,
  ): Promise<DeleteManyResult<T>> => {
    if (!(await canAccess({ resource, action: 'write' }))) {
      throw new Error('Insufficient permissions');
    }

    const payload = JSON.stringify({
      'atomic:operations': params.ids.map((id) => ({
        op: 'remove',
        ref: { id, type: resource },
      })),
    });

    await httpClient(`${apiUrl}/operations`, {
      method: 'POST',
      body: payload,
      headers: new Headers({
        'Content-Type': 'application/vnd.api+json; ext="https://jsonapi.org/ext/atomic"',
        Accept: 'application/vnd.api+json; ext="https://jsonapi.org/ext/atomic"',
      }),
    });

    return { data: params.ids };
  };
}
