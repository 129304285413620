import { ReactElement } from 'react';
import {
  AutocompleteArrayInput,
  CreateButton,
  DatagridConfigurable,
  List,
  ListProps,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceField,
  SelectArrayInput,
  TextArrayInput,
  TextField,
  useCanAccess,
  WithRecord,
} from 'react-admin';
import LargePagination from '../../Components/LargePagination';
import DraftMappingRuleResource from '../../Entities/DraftMappingRule';
import { createFilterQuery } from '../../utils';

const filters = [
  <ReferenceArrayInput
    key="merchant_id"
    label="Merchant"
    source="merchant.id"
    reference="merchants"
  >
    <AutocompleteArrayInput
      label="Merchant"
      optionText="attributes.merchant_name"
      filterToQuery={createFilterQuery(['merchant_name'])}
    />
  </ReferenceArrayInput>,
  <ReferenceArrayInput
    key="merchant_status_id"
    label="Merchant Status"
    source="merchant.merchant_status.id"
    reference="merchant_statuses"
  >
    <SelectArrayInput label="Merchant Status" optionText="attributes.name" />
  </ReferenceArrayInput>,
  <ReferenceArrayInput
    key="region_id"
    label="Merchant Region"
    source="merchant.region.id"
    reference="regions"
  >
    <AutocompleteArrayInput
      label="Merchant Region"
      optionText="attributes.name"
      filterToQuery={createFilterQuery()}
    />
  </ReferenceArrayInput>,
  <TextArrayInput
    key="contains_external_category"
    label="External Category Contains"
    source="contains.external_category"
  />,
  <TextArrayInput
    key="not_contains_external_category"
    label="External Category Does Not Contain"
    source="not.contains.external_category"
  />,
  <NumberInput
    key="greaterThan_offer_count"
    label="Offer Count Greater Than"
    source="greaterThan.offer_count"
  />,
  <NumberInput
    key="lessThan_offer_count"
    label="Offer Count Less Than"
    source="lessThan.offer_count"
  />,
];

export default function DraftMappingRulesList(props: ListProps): ReactElement {
  const merchantListAccess = useCanAccess({ resource: 'merchants', action: 'list' });
  const merchantStatusListAccess = useCanAccess({ resource: 'merchant_statuses', action: 'list' });
  const regionListAccess = useCanAccess({ resource: 'regions', action: 'list' });

  const canListMerchants = merchantListAccess.canAccess ?? false;
  const canListMerchantStatuses = merchantStatusListAccess.canAccess ?? false;
  const canListRegions = regionListAccess.canAccess ?? false;

  const prefetch: string[] = [
    canListMerchants && 'merchant',
    canListMerchantStatuses && 'merchant.merchant_status',
    canListRegions && 'merchant.region',
  ].filter((v) => typeof v === 'string');

  return (
    <List
      {...props}
      filters={filters}
      filterDefaultValues={{ merchant: { merchant_status: { id: '1' }, region: { id: 1 } } }}
      pagination={<LargePagination />}
      perPage={25}
      queryOptions={{ meta: { prefetch } }}
      sort={{ field: 'attributes.offer_count', order: 'DESC' }}
    >
      <DatagridConfigurable bulkActionButtons={false}>
        <ReferenceField
          label="Merchant"
          source="relationships.merchant.data.id"
          reference="merchants"
        >
          <TextField source="attributes.merchant_name" />
        </ReferenceField>
        <ReferenceField
          label="Merchant Status"
          source="relationships.merchant.data.id"
          reference="merchants"
        >
          <ReferenceField
            label="Merchant Status"
            source="relationships.merchant_status.data.id"
            reference="merchant_statuses"
          >
            <TextField label="Merchant Status" source="attributes.name" />
          </ReferenceField>
        </ReferenceField>
        <ReferenceField
          label="Merchant Region"
          source="relationships.merchant.data.id"
          reference="merchants"
        >
          <ReferenceField
            label="Merchant Region"
            source="relationships.region.data.id"
            reference="regions"
          >
            <TextField label="Merchant Region" source="attributes.name" />
          </ReferenceField>
        </ReferenceField>
        <TextField label="External Category" source="attributes.external_category" />
        <NumberField label="Offer Count" source="attributes.offer_count" />
        <WithRecord
          render={(record: DraftMappingRuleResource) => (
            <CreateButton
              resource="mapping_rules"
              label="Create New Mapping Rule"
              state={{
                record: {
                  attributes: {
                    external_category: record.attributes.external_category,
                  },
                  relationships: {
                    merchant: {
                      data: {
                        id: record.relationships.merchant.data?.id,
                        type: 'merchants',
                      },
                    },
                  },
                },
              }}
            />
          )}
        />
      </DatagridConfigurable>
    </List>
  );
}
