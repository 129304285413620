import {
  fetchUtils,
  GetManyParams,
  GetManyResult,
  HttpError,
  Identifier,
  RaRecord,
} from 'react-admin';
import { defaultHeaders, transformIds } from './utils';
import createFilter from './JsonApiFiltering';
import { JsonApiCollectionResponse, JsonApiResource } from './JsonApiTypes';

export default function getMany(apiUrl: string, httpClient = fetchUtils.fetchJson) {
  return async <T extends RaRecord<Identifier>>(
    resource: string,
    params: GetManyParams,
  ): Promise<GetManyResult<T & JsonApiResource>> => {
    const query = new URLSearchParams();
    const filter = createFilter({ id: transformIds(params.ids) });

    if (filter) {
      query.set('filter', filter);
    }

    params.meta?.prefetch?.forEach((prefetch: string) => {
      query.append('include', prefetch);
    });

    query.set('page[size]', String(params.ids.length));

    const url = `${apiUrl}/${resource}?${query}`;

    try {
      const response = await httpClient(url, { headers: defaultHeaders, signal: params.signal });
      const result = response.json as JsonApiCollectionResponse<string, T>;

      const data = result.data as (T & JsonApiResource)[];
      const prefetched = result.included?.reduce(
        (acc, includedRecord) => {
          acc[includedRecord.type] ??= [];
          acc[includedRecord.type].push(includedRecord);

          return acc;
        },
        {} as Record<string, JsonApiResource[]>,
      );

      return { data, meta: { prefetched } };
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        throw error;
      }
      throw new HttpError(
        (error as HttpError).body?.errors[0].detail ?? 'Error fetching records',
        (error as HttpError).body?.status ?? 500,
        (error as HttpError).body,
      );
    }
  };
}
