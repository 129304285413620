import { Edit, TabbedForm, useCanAccess } from 'react-admin';
import { RegistrationResource } from '../../Entities/Registration';
import SummaryTab from './Forms/SummaryTab';
import ContactInfoTab from './Forms/ContactInfoTab';
import AddressTab from './Forms/AddressTab';
import MerchantConfigTab from './Forms/MerchantConfigTab';
import EditToolbar from '../../Components/EditToolbar';
import { ReactElement } from 'react';

export default function RegistrationEdit(): ReactElement {
  const merchantListAccess = useCanAccess({ action: 'list', resource: 'merchants' });
  const regionListAccess = useCanAccess({ action: 'list', resource: 'regions' });

  const prefetch: string[] = [
    merchantListAccess.canAccess && 'merchant',
    regionListAccess.canAccess && 'region',
  ].filter((i) => typeof i === 'string');

  return (
    <Edit<RegistrationResource> mutationMode="pessimistic" queryOptions={{ meta: { prefetch } }}>
      <TabbedForm toolbar={<EditToolbar />}>
        <SummaryTab />
        <MerchantConfigTab />
        <AddressTab />
        <ContactInfoTab />
      </TabbedForm>
    </Edit>
  );
}
