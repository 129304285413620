import { DateTimeInput, DateTimeInputProps } from '@react-admin/ra-form-layout';
import { Configurable, usePreference } from 'react-admin';
import TzDatePreferencesEditor from './TzDatePreferencesEditor';
import { supportedDateFormats, supportedTimeFormats, LOCAL_TIMEZONE, format, parse } from './utils';
import { ReactElement } from 'react';

type TzDateTimeInputImplProps = DateTimeInputProps & {
  translateTimezone?: boolean;
  precision?: 'millisecond' | 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
};

function TzDateTimeInputImpl({
  translateTimezone,
  precision,
  format: userFormatFn,
  parse: userParseFn,
  ...dateInputProps
}: TzDateTimeInputImplProps): ReactElement {
  const [dateFormat] = usePreference('format.date', supportedDateFormats[0].id);
  const [timeFormat] = usePreference('format.time', supportedTimeFormats[0].id);
  const [timezone] = usePreference('timezone', LOCAL_TIMEZONE);

  const formatFn = format({ userFormatFn, translateTimezone, timezone, precision });
  const parseFn = parse({ userParseFn, translateTimezone, precision });

  return (
    <DateTimeInput
      ampm={timeFormat.includes('a')}
      mask={`${dateFormat} ${timeFormat}`}
      format={formatFn}
      parse={parseFn}
      {...dateInputProps}
    />
  );
}

export type TzDateTimeInputProps = TzDateTimeInputImplProps & { preferenceKey?: string };

/**
 * Creates a timezone-aware DateTimeInput component. This component wraps
 * React-Admin's `<DateTimeInput /> ` component, but overloads the input's
 * `format` and `parse` functions to achieve any required timezone translations.
 *
 * This does mean that there are some caveats to the `format` and `parse` props
 * on this component to consider above those of the base React-Admin functions.
 *
 * On the `format` function, the value passed to the user-supplied `format`
 * function will be the in-memory form state as usual, but it should always
 * return an empty string (if the value is empty) or a fully-formatted ISO-8601
 * string. This string will be translated to the user's local timezone in the
 * form if `translateTimezone` is set [default].
 *
 * For `parse`, the value passed to the user-supplied `parse` function will be
 * an ISO-8601 datetime without timezone information, or null for no value. The
 * function should return the new form state value as usual. This date will be
 * translated to the server's timezone if `translateTimezone` is set [default].
 *
 * Similarly, the `mask` prop on the default ` < DateTimeInput /> ` component is
 * not available on this component, as it is automatically set to the user's
 * chosen date and time format preferences.
 */
export default function TzDateTimeInput({
  preferenceKey = 'tzDatePreferences',
  ...rest
}: TzDateTimeInputProps): ReactElement {
  return (
    <Configurable
      editor={<TzDatePreferencesEditor showTimezone={false} showTime />}
      preferenceKey={preferenceKey}
      sx={{ width: '100%' }}
    >
      <TzDateTimeInputImpl {...rest} />
    </Configurable>
  );
}
