import {
  CreateParams,
  CreateResult,
  fetchUtils,
  HttpError,
  Identifier,
  RaRecord,
} from 'react-admin';
import { defaultHeaders } from './utils';
import { JsonApiResource, JsonApiSingleResponse } from './JsonApiTypes';

export default function create(apiUrl: string, httpClient = fetchUtils.fetchJson) {
  return async <T extends RaRecord<Identifier>>(
    resource: string,
    params: CreateParams<T>,
  ): Promise<CreateResult<T & JsonApiResource>> => {
    const payload = { data: params.data } as Partial<JsonApiResource>;
    payload.data.type = resource;

    const query = new URLSearchParams();

    params.meta?.prefetch?.forEach((prefetch: string) => {
      query.append('include', prefetch);
    });

    const url = `${apiUrl}/${resource}?${query}`;

    try {
      const response = await httpClient(url, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: defaultHeaders,
      });
      const result = response.json as JsonApiSingleResponse;
      const { data } = result;

      if (!data) {
        throw new HttpError(
          'The server returned an empty response',
          response.status,
          response.body,
        );
      }

      return { data } as CreateResult<T & JsonApiResource>;
    } catch (error) {
      throw new HttpError(
        (error as HttpError).body?.errors[0].detail ?? 'Error creating record',
        (error as HttpError).body.status ?? 500,
        (error as HttpError).body,
      );
    }
  };
}
