import { BrowserRouter, Route } from 'react-router-dom';
import { Admin, defaultTheme, defaultDarkTheme, CustomRoutes, Resource } from 'react-admin';
import {
  AdsClick,
  Category as CategoryIcon,
  DynamicFeed,
  Factory,
  Lan,
  LocalOffer,
  Person,
  PhoneIphone,
  ReceiptLong,
  Rule,
  RuleFolderOutlined,
  ShoppingCart,
  TravelExplore,
} from '@mui/icons-material';
import Dashboard from './Components/Dashboard';
import { AuthProvider, DataProvider } from './DataProvider';
import * as AffiliateNetwork from './Resources/AffiliateNetwork';
import * as BillingItem from './Resources/BillingItems';
import * as Category from './Resources/Category';
import * as Click from './Resources/Click';
import * as Feed from './Resources/Feed';
import * as Manufacturer from './Resources/Manufacturer';
import * as MappingRule from './Resources/MappingRules';
import * as Merchant from './Resources/Merchant';
import * as Offer from './Resources/Offer';
import * as Product from './Resources/Product';
import * as Region from './Resources/Region';
import * as Registration from './Resources/Registration';
import Layout from './Components/Layout';
import Preferences from './CustomRoutes/Preferences';
import Login from './CustomRoutes/Login';
import { ReactElement } from 'react';

export default function App(): ReactElement {
  const apiUrl = import.meta.env.VITE_API_URL;

  return (
    <BrowserRouter>
      <Admin
        authProvider={AuthProvider}
        dataProvider={DataProvider(apiUrl, AuthProvider)}
        theme={defaultTheme}
        darkTheme={defaultDarkTheme}
        dashboard={Dashboard}
        disableTelemetry
        layout={Layout}
        loginPage={Login}
        requireAuth
      >
        <Resource
          name="categories"
          icon={CategoryIcon}
          create={Category.Create}
          edit={Category.Edit}
          list={Category.List}
        />
        <Resource
          name="products"
          icon={PhoneIphone}
          create={Product.Create}
          edit={Product.Edit}
          list={Product.List}
        />
        <Resource name="offers" icon={LocalOffer} edit={Offer.Edit} list={Offer.List} />
        <Resource
          name="merchants"
          icon={ShoppingCart}
          create={Merchant.Create}
          edit={Merchant.Edit}
          list={Merchant.List}
          recordRepresentation={(record) => `${record.attributes.merchant_name}`}
        />
        <Resource
          name="feeds"
          icon={DynamicFeed}
          create={Feed.Create}
          edit={Feed.Edit}
          list={Feed.List}
        />
        <Resource
          name="registrations"
          icon={Person}
          create={Registration.Create}
          edit={Registration.Edit}
          list={Registration.List}
        />
        <Resource
          name="manufacturers"
          icon={Factory}
          create={Manufacturer.Create}
          edit={Manufacturer.Edit}
          list={Manufacturer.List}
        />
        <Resource
          name="regions"
          icon={TravelExplore}
          create={Region.Create}
          edit={Region.Edit}
          list={Region.List}
        />
        <Resource
          name="affiliate_networks"
          icon={Lan}
          create={AffiliateNetwork.Create}
          edit={AffiliateNetwork.Edit}
          list={AffiliateNetwork.List}
          options={{ label: 'Affiliate Networks' }}
        />
        <Resource
          name="mapping_rules"
          options={{ label: 'Mapping Rules' }}
          icon={Rule}
          create={MappingRule.Create}
          edit={MappingRule.Edit}
          list={MappingRule.List}
        />
        <Resource
          name="draft_mapping_rules"
          options={{ label: 'Draft Mapping Rules' }}
          icon={RuleFolderOutlined}
          list={MappingRule.DraftMappingRulesList}
        />
        <Resource name="clicks" icon={AdsClick} list={Click.List} options={{ label: 'Clicks' }} />
        <Resource
          name="billing_items"
          icon={ReceiptLong}
          list={BillingItem.List}
          edit={BillingItem.Edit}
          options={{ label: 'Billing Items' }}
        />
        <CustomRoutes>
          <Route path="/preferences" element={<Preferences />} />
        </CustomRoutes>
      </Admin>
    </BrowserRouter>
  );
}
