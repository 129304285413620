import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
import {
  TabbedForm,
  TextInput,
  maxLength,
  NumberInput,
  SelectInput,
  TabbedFormViewProps,
  Labeled,
  CreateButton,
  useRecordContext,
  useCanAccess,
  required,
  ReferenceField,
} from 'react-admin';
import { Box, Divider, Grid2 as Grid, Tooltip } from '@mui/material';
import { REGISTRATION_STATUS_CHOICES, registrationToMerchant } from '../utils';
import { RegistrationResource } from '../../../Entities/Registration';
import TzDateField from '../../../Components/TzDate/TzDateField';
import { ReactElement } from 'react';
import { useWatch } from 'react-hook-form';

export default function SummaryTab(props: TabbedFormViewProps): ReactElement {
  const merchantAccess = useCanAccess({ action: 'list', resource: 'merchants' });
  const canListMerchants = merchantAccess.canAccess ?? false;

  const record = useRecordContext<RegistrationResource>();

  const companyName = useWatch({ name: 'attributes.company_name' }) as string | undefined;
  const merchantName = useWatch({ name: 'attributes.merchant_name' }) as string | undefined;
  const merchantId = useWatch({ name: 'relationships.merchant.data.id' }) as string | undefined;

  return (
    <TabbedForm.Tab label="Summary" {...props}>
      <Grid container spacing={2} sx={{ width: '100%' }}>
        {record && record.id && (
          <Grid size={12}>
            <Box flex={1}>
              <Labeled label="Added On">
                <TzDateField source="attributes.added_on" />
              </Labeled>
            </Box>
            <Divider flexItem />
          </Grid>
        )}
        <Grid size={{ xs: 12, xl: 6 }}>
          <Box flex={1}>
            <TextInput
              label="Company Name"
              source="attributes.company_name"
              validate={
                merchantName === undefined
                  ? [
                      maxLength(255),
                      required('At least one of Company Name or Merchant Name must be filled out'),
                    ]
                  : maxLength(255)
              }
              isRequired={merchantName === undefined}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              label="Merchant Name"
              source="attributes.merchant_name"
              validate={
                companyName === undefined
                  ? [
                      maxLength(255),
                      required('At least one of Company Name or Merchant Name must be filled out'),
                    ]
                  : maxLength(255)
              }
              isRequired={companyName === undefined}
            />
          </Box>
          <Box flex={1}>
            <TextInput label="Website" source="attributes.website" validate={maxLength(255)} />
          </Box>
          <Box flex={1}>
            <RichTextInput
              label="Description"
              source="attributes.description"
              fullWidth
              validate={maxLength(1400)}
              toolbar={<RichTextInputToolbar size="small" />}
            />
          </Box>
          <Box flex={1}>
            <NumberInput label="CPC" source="attributes.cpc" />
          </Box>
          <Box flex={1}>
            <Tooltip title="Australian Business Number">
              <TextInput label="ABN" source="attributes.abn" validate={maxLength(255)} />
            </Tooltip>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, xl: 6 }}>
          <Box flex={1}>
            <SelectInput
              label="Status"
              source="attributes.status"
              choices={REGISTRATION_STATUS_CHOICES}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              label="Salesperson"
              source="attributes.salesperson"
              validate={maxLength(255)}
            />
          </Box>
          {canListMerchants && record?.id && (
            <Box flex={1}>
              <Labeled label="Merchant">
                {merchantId ? (
                  <ReferenceField reference="merchants" source="relationships.merchant.data.id" />
                ) : (
                  <CreateButton
                    resource="merchants"
                    state={{ record: registrationToMerchant(record) }}
                  />
                )}
              </Labeled>
            </Box>
          )}
        </Grid>
      </Grid>
    </TabbedForm.Tab>
  );
}
