import {
  fetchUtils,
  GetListParams,
  GetListResult,
  HttpError,
  Identifier,
  RaRecord,
} from 'react-admin';
import { JsonApiCollectionResponse, JsonApiResource, JsonApiResourceLinkage } from './JsonApiTypes';
import { createSort, defaultHeaders } from './utils';
import createFilter from './JsonApiFiltering';
import type { default as GetOne } from './getOne';

export default function getList(
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
  getOne: ReturnType<typeof GetOne>,
) {
  async function ensureIncluded<T extends RaRecord & JsonApiResource>(
    response: GetListResult<T>,
    link?: null | JsonApiResourceLinkage<T['id']>,
  ): Promise<GetListResult<T>> {
    if (link && !response.data.some((d) => d.id === link.id)) {
      const item = await getOne<T>(link.type, { id: link.id });
      response.data.push(item.data);
    }

    return response;
  }

  return async <T extends RaRecord<Identifier>>(
    resource: string,
    params: GetListParams,
  ): Promise<GetListResult<T & JsonApiResource>> => {
    const { filter, meta } = params;

    const data: (T & JsonApiResource)[] = [];

    const query = new URLSearchParams();

    params.meta?.prefetch?.forEach((prefetch: string) => {
      query.append('include', prefetch);
    });

    if (params.pagination) {
      query.set('page[number]', String(params.pagination.page));
      query.set('page[size]', String(params.pagination.perPage));
    }
    if (params.sort) {
      query.set('sort', createSort(params.sort));
    }

    const filterString = createFilter(filter);

    if (filterString) {
      query.set('filter', filterString);
    }

    const url = `${apiUrl}/${resource}?${query}`;

    try {
      const response = await httpClient(url, { headers: defaultHeaders, signal: params.signal });
      const result = response.json as JsonApiCollectionResponse;
      const total = Number(result.meta?.total ?? 0) + data.length;

      const fetchedData = (result.data ?? []) as (T & JsonApiResource)[];
      data.push(...fetchedData);

      const hasNextPage = result.links?.next !== null;
      const hasPreviousPage = result.links?.prev !== null;

      const prefetched = result.included?.reduce(
        (acc, includedRecord) => {
          acc[includedRecord.type] ??= [];
          acc[includedRecord.type].push(includedRecord);
          return acc;
        },
        {} as Record<string, JsonApiResource[]>,
      );

      return ensureIncluded(
        { data, total, meta: { prefetched }, pageInfo: { hasNextPage, hasPreviousPage } },
        meta?.ensureIncluded,
      );
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        throw error;
      }
      throw new HttpError(
        (error as HttpError).body?.errors[0].detail ?? 'Error fetching records',
        (error as HttpError).body?.status ?? 500,
        (error as HttpError).body,
      );
    }
  };
}
