import { ReactElement } from 'react';
import { Edit, TabbedForm, useCanAccess } from 'react-admin';
import EditToolbar from '../../Components/EditToolbar';
import FeedFieldsForm from './Forms/FeedFieldsForm';
import FeedSummaryForm from './Forms/FeedSummaryForm';

export default function FeedEdit(): ReactElement {
  const merchantListAccess = useCanAccess({ action: 'list', resource: 'merchants' });
  const feedFieldListAccess = useCanAccess({ action: 'list', resource: 'feed_fields' });

  const prefetch: string[] = [
    merchantListAccess.canAccess && 'merchant',
    feedFieldListAccess.canAccess && 'fields',
  ].filter((i) => typeof i === 'string');

  return (
    <Edit mutationMode="pessimistic" queryOptions={{ meta: { prefetch } }}>
      <TabbedForm toolbar={<EditToolbar />}>
        <TabbedForm.Tab label="Summary">
          <FeedSummaryForm />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Fields">
          <FeedFieldsForm />
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
}
