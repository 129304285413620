import {
  ChipField,
  CreateButton,
  FunctionField,
  ImageField,
  Labeled,
  NumberInput,
  ReferenceManyField,
  SingleFieldList,
  TabbedForm,
  TabbedFormViewProps,
  TextInput,
  maxLength,
  required,
  useCanAccess,
} from 'react-admin';
import { Box, Divider, Grid2 as Grid } from '@mui/material';
import { MerchantResource } from '../../../Entities/Merchant';
import SadPanda from '../../../Components/SadPanda';
import { RegistrationResource } from '../../../Entities/Registration';
import { AffiliateNetworkResource } from '../../../Entities/AffiliateNetwork';
import { BILLING_TYPE_CHOICES } from '../utils';
import { ReactElement } from 'react';
import JsonApiHasOneReferenceInput from '../../../Components/JsonApiHasOneReferenceInput';
import { useWatch } from 'react-hook-form';
import { JsonApiResourceLinkage } from '../../../DataProvider';

export default function SummaryTab(props: TabbedFormViewProps): ReactElement {
  const affiliateNetworkAccess = useCanAccess({ resource: 'affiliate_networks', action: 'list' });
  const feedAccess = useCanAccess({ resource: 'feeds', action: 'list' });
  const registrationAccess = useCanAccess({ resource: 'registrations', action: 'list' });
  const canListAffiliateNetworks = affiliateNetworkAccess.canAccess ?? false;
  const canListFeeds = feedAccess.canAccess ?? false;
  const canListRegistrations = registrationAccess.canAccess ?? false;

  const billingType = useWatch({ name: 'attributes.billing_type_code' }) as number | undefined;
  const isCPAMerchant = billingType === BILLING_TYPE_CHOICES.find((v) => v.name === 'CPA')?.id;

  const id = useWatch({ name: 'id' }) as string | undefined;
  const feeds = useWatch({ name: 'relationships.feeds.data' }) as
    | JsonApiResourceLinkage<'feeds'>[]
    | undefined;

  return (
    <TabbedForm.Tab label="Summary" {...props}>
      <Labeled>
        <ImageField label="Logo" source="attributes.logo_url" />
      </Labeled>
      <Labeled>
        <SadPanda label="Upload New Logo" ticket="https://purch1.atlassian.net/browse/GET-672" />
      </Labeled>
      <Labeled>
        <FunctionField
          label="Merchant Portal Link"
          render={(record?: Partial<MerchantResource>) => {
            const username = record?.attributes?.username;

            if (!username) {
              return <i>Unknown</i>;
            }

            return (
              <a
                href={`https://merchant.getprice.com.au/Login.aspx?u=${username}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {username}
              </a>
            );
          }}
        />
      </Labeled>
      <Divider flexItem />
      <Grid container spacing={2} sx={{ width: '100%' }}>
        <Grid size={{ xs: 12, xl: 6 }}>
          <Box flex={1}>
            <TextInput
              label="Company Name"
              source="attributes.company_name"
              validate={maxLength(200)}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              label="Merchant Name"
              source="attributes.merchant_name"
              validate={[required(), maxLength(200)]}
            />
          </Box>
          <Box flex={1}>
            <TextInput label="Website" source="attributes.website" validate={maxLength(200)} />
          </Box>
          <NumberInput
            defaultValue={40}
            isRequired
            label="CPC"
            source="attributes.cpc"
            step={1}
            validate={required()}
          />
          <Box flex={1}>
            <TextInput
              label="Description"
              multiline
              source="attributes.description"
              validate={maxLength(1400)}
            />
          </Box>
        </Grid>
        <Grid size={{ xs: 12, xl: 6 }}>
          <Box flex={1}>
            <JsonApiHasOneReferenceInput
              source="relationships.merchant_status"
              reference="merchant_statuses"
              optionText="attributes.name"
              label="Merchant Status"
              isRequired
              validate={required()}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              label="Salesperson"
              source="attributes.salesperson"
              validate={maxLength(100)}
            />
          </Box>
          {canListRegistrations && (
            <Box flex={1}>
              <JsonApiHasOneReferenceInput<MerchantResource, 'registration'>
                emptyText="Record missing"
                filterFields={['merchant_name', 'company_name']}
                label="Registration"
                optionText={(choice: RegistrationResource) =>
                  `${choice.attributes.company_name} - ${choice.attributes.merchant_name} (${choice.id})`
                }
                reference="registrations"
                source="relationships.registration"
              />
            </Box>
          )}
          {canListAffiliateNetworks && isCPAMerchant && (
            <Box flex={1}>
              <JsonApiHasOneReferenceInput<MerchantResource, 'affiliate_network'>
                emptyText="Record missing"
                filterFields={['name', 'subtag_name']}
                label="Affiliate Network"
                optionText={(choice: AffiliateNetworkResource) =>
                  `${choice.attributes.name} - ${choice.attributes.subtag_name} (${choice.id})`
                }
                reference="affiliate_networks"
                source="relationships.affiliate_network"
              />
            </Box>
          )}
          {canListFeeds && id && (
            <Box flex={1}>
              <Labeled label="Feeds">
                {(feeds?.length ?? 0 > 0) ? (
                  <ReferenceManyField reference="feeds" target="merchant.id">
                    <SingleFieldList>
                      <ChipField source="id" />
                    </SingleFieldList>
                  </ReferenceManyField>
                ) : (
                  <CreateButton
                    resource="feeds"
                    state={{
                      record: { relationships: { merchant: { data: { type: 'merchants', id } } } },
                    }}
                  />
                )}
              </Labeled>
            </Box>
          )}
        </Grid>
      </Grid>
    </TabbedForm.Tab>
  );
}
