import { ReactElement } from 'react';
import {
  AutocompleteInput,
  Edit,
  Labeled,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  TextField,
  useCanAccess,
} from 'react-admin';
import EditToolbar from '../../Components/EditToolbar';
import ActionPanel from './ActionPanel';
import { createFilterQuery } from '../../utils';

export default function MappingRulesEdit(): ReactElement {
  const merchantListAccess = useCanAccess({ action: 'list', resource: 'merchants' });
  const categoryListAccess = useCanAccess({ action: 'list', resource: 'categories' });

  const prefetch: string[] = [
    merchantListAccess.canAccess && 'merchant',
    categoryListAccess.canAccess && 'category',
  ].filter((i) => typeof i === 'string');

  return (
    <Edit queryOptions={{ meta: { prefetch } }}>
      <SimpleForm toolbar={<EditToolbar />}>
        <Labeled>
          <ReferenceField
            reference="merchants"
            source="relationships.merchant.data.id"
            label="Merchant"
          />
        </Labeled>
        <Labeled>
          <TextField source="attributes.external_category" label="External Category" />
        </Labeled>
        <ReferenceInput
          reference="categories"
          source="relationships.category.data.id"
          label="Category"
          filter={{ level: 0 }}
        >
          <AutocompleteInput
            label="Category"
            optionText="attributes.full_name"
            filterToQuery={createFilterQuery()}
          />
        </ReferenceInput>
        <ActionPanel />
      </SimpleForm>
    </Edit>
  );
}
