import { Edit, TabbedForm, useCanAccess } from 'react-admin';
import { MerchantResource } from '../../Entities/Merchant';
import SummaryTab from './Forms/SummaryTab';
import ContactInfoTab from './Forms/ContactInfoTab';
import AddressTab from './Forms/AddressTab';
import BillingInfoTab from './Forms/BillingInfoTab';
import MerchantConfigTab from './Forms/MerchantConfigTab';
import RemarksTab from './Forms/RemarksTab';
import ScannerTab from './Forms/ScannerTab';
import EditToolbar from '../../Components/EditToolbar';
import { ReactElement } from 'react';

export default function MerchantEdit(): ReactElement {
  const affiliateNetworkAccess = useCanAccess({ action: 'list', resource: 'affiliate_networks' });
  const countryAccess = useCanAccess({ action: 'list', resource: 'countries' });
  const merchantStatusAccess = useCanAccess({ action: 'list', resource: 'merchant_statuses' });
  const registrationAccess = useCanAccess({ action: 'list', resource: 'registrations' });
  const regionAccess = useCanAccess({ action: 'list', resource: 'regions' });
  const shopScansAccess = useCanAccess({ action: 'list', resource: 'shop_scans' });
  const stateAccess = useCanAccess({ action: 'list', resource: 'states' });

  const prefetch: string[] = [
    affiliateNetworkAccess.canAccess && 'affiliate_network',
    countryAccess.canAccess && 'country',
    merchantStatusAccess.canAccess && 'merchant_status',
    registrationAccess.canAccess && 'registration',
    regionAccess.canAccess && 'region',
    shopScansAccess.canAccess && 'shop_scans',
    stateAccess.canAccess && 'state',
  ].filter((i) => typeof i === 'string');

  return (
    <Edit<MerchantResource> mutationMode="pessimistic" queryOptions={{ meta: { prefetch } }}>
      <TabbedForm toolbar={<EditToolbar />}>
        <SummaryTab />
        <MerchantConfigTab />
        <AddressTab />
        <ContactInfoTab />
        <BillingInfoTab />
        <ScannerTab />
        <RemarksTab />
      </TabbedForm>
    </Edit>
  );
}
