import {
  fetchUtils,
  GetManyReferenceParams,
  GetManyReferenceResult,
  HttpError,
  Identifier,
  RaRecord,
} from 'react-admin';
import { createSort, defaultHeaders, deriveFilterFromTarget } from './utils';
import createFilter from './JsonApiFiltering';
import { JsonApiCollectionResponse, JsonApiResource } from './JsonApiTypes';

export default function getManyReference(apiUrl: string, httpClient = fetchUtils.fetchJson) {
  return async <T extends RaRecord<Identifier>>(
    resource: string,
    params: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<T & JsonApiResource>> => {
    const query = new URLSearchParams();

    if (params.pagination.perPage === 0) {
      return { data: [], total: 0 };
    }

    params.meta?.prefetch?.forEach((prefetch: string) => {
      query.append('include', prefetch);
    });

    query.set('page[number]', String(params.pagination.page));
    query.set('page[size]', String(params.pagination.perPage));
    query.set('sort', createSort(params.sort));

    const target = deriveFilterFromTarget(params.target);

    const filter = createFilter({
      ...params.filter,
      [target]: params.id,
    });

    if (filter) {
      query.set('filter', filter);
    }

    const url = `${apiUrl}/${resource}?${query}`;

    try {
      const response = await httpClient(url, { headers: defaultHeaders, signal: params.signal });
      const result = response.json as JsonApiCollectionResponse;

      const { data, included, meta, links } = result;
      const total = Number(meta?.total ?? data?.length);

      const hasNextPage = links?.next !== undefined;
      const hasPreviousPage = links?.prev !== undefined;

      const prefetched = included?.reduce(
        (acc, includedRecord) => {
          acc[includedRecord.type] ??= [];
          acc[includedRecord.type].push(includedRecord);
          return acc;
        },
        {} as Record<string, JsonApiResource[]>,
      );

      return {
        data: data as (T & JsonApiResource)[],
        total,
        meta: { prefetched },
        pageInfo: { hasNextPage, hasPreviousPage },
      };
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        throw error;
      }
      throw new HttpError(
        (error as HttpError).body?.errors[0].detail ?? 'Error fetching records',
        (error as HttpError).body.status ?? 500,
        (error as HttpError).body,
      );
    }
  };
}
