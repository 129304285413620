import { List } from '@react-admin/ra-rbac';
import {
  AutocompleteArrayInput,
  BooleanField,
  DatagridConfigurable,
  EmailField,
  FunctionField,
  ImageField,
  NullableBooleanInput,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceField,
  SelectArrayInput,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  UrlField,
  useCanAccess,
} from 'react-admin';
import { BILLING_TYPE_CHOICES, MAIL_CAP_STATUS_CHOICES, PAYMENT_TYPE_CHOICES } from './utils';
import {
  DEFAULT_LIST_PAGE_IMAGE_MAX_HEIGHT,
  DEFAULT_LIST_PAGE_IMAGE_MAX_WIDTH,
  DEFAULT_LIST_PAGE_PAGINATION_LIMIT,
} from '../../utils';
import { MerchantResource } from '../../Entities/Merchant';
import { ReactElement } from 'react';

const filters = (
  canListAffiliateNetworks: boolean,
  canListMerchantStatuses: boolean,
  canListRegions: boolean,
): ReactElement[] =>
  [
    <NumberInput alwaysOn key="filter.id" label="ID" source="equals.id" />,
    <TextInput key="filter.company_name" label="Company Name" source="contains.company_name" />,
    <TextInput
      alwaysOn
      key="filter.merchant_name"
      label="Merchant Name"
      source="contains.merchant_name"
    />,
    canListRegions && (
      <ReferenceArrayInput
        alwaysOn
        key="filter.region.id"
        source="equals.region.id"
        reference="regions"
      >
        <SelectArrayInput label="Region" optionText="attributes.name" />
      </ReferenceArrayInput>
    ),
    canListAffiliateNetworks && (
      <ReferenceArrayInput
        key="filter.affiliate_network.id"
        source="equals.affiliate_network.id"
        reference="affiliate_networks"
        target="id"
        alwaysOn
      >
        <AutocompleteArrayInput label="Affiliate Network" optionText="attributes.name" />
      </ReferenceArrayInput>
    ),
    <TextInput key="filter.website" label="Website" source="contains.website" />,
    canListMerchantStatuses && (
      <ReferenceArrayInput
        alwaysOn
        key="filter.merchant_status.id"
        source="any.merchant_status.id"
        reference="merchant_statuses"
      >
        <SelectArrayInput label="Merchant Status" optionText="attributes.name" />
      </ReferenceArrayInput>
    ),
    <NumberInput key="filter.cpc" label="CPC" source="equals.cpc" />,
    <NumberInput key="filter.cpc_gt" label="CPC: More than" source="greaterThan.cpc" />,
    <NumberInput key="filter.cpc_lt" label="CPC: Less than" source="lessThan.cpc" />,
    <TextInput
      key="filter.primary_contact"
      label="Primary Contact"
      source="contains.primary_contact"
    />,
    <TextInput key="filter.phone_number" label="Phone Number" source="contains.phone_number" />,
    <TextInput key="filter.email" label="Email" source="equals.email" />,
    <NullableBooleanInput
      key="filter.over_budget"
      label="Over Budget?"
      source="equals.over_budget"
    />,
    <SelectInput
      key="filter.mail_cap_status"
      label="Mail Cap Status"
      source="equals.mail_cap_status_code"
      choices={MAIL_CAP_STATUS_CHOICES}
    />,
    <SelectInput
      key="filter.billing_type"
      label="Billing Type"
      source="equals.billing_type_code"
      choices={BILLING_TYPE_CHOICES}
    />,
    <SelectInput
      key="filter.payment_type"
      label="Payment Type"
      source="equals.payment_type_code"
      choices={PAYMENT_TYPE_CHOICES}
    />,
    <NullableBooleanInput
      key="filter.foreign_company_status"
      label="Is Foreign Company?"
      source="equals.foreign_company_status_code"
    />,
  ].filter((value) => typeof value !== 'boolean');

export default function MerchantList(): ReactElement {
  const affiliateNetworkAccess = useCanAccess({
    resource: 'affiliate_networks',
    action: 'list',
  });
  const merchantStatusAccess = useCanAccess({ resource: 'merchant_statuses', action: 'list' });
  const regionAccess = useCanAccess({ resource: 'regions', action: 'list' });
  const registrationAccess = useCanAccess({ resource: 'registrations', action: 'list' });

  const canListAffiliateNetworks = affiliateNetworkAccess.canAccess ?? false;
  const canListMerchantStatuses = merchantStatusAccess.canAccess ?? false;
  const canListRegions = regionAccess.canAccess ?? false;
  const canListRegistrations = registrationAccess.canAccess ?? false;

  const prefetch: string[] = [
    canListAffiliateNetworks && 'affiliate_network',
    canListMerchantStatuses && 'merchant_status',
    canListRegions && 'region',
    canListRegistrations && 'registration',
  ].filter((i) => typeof i === 'string');

  return (
    <List
      filters={filters(canListAffiliateNetworks, canListMerchantStatuses, canListRegions)}
      perPage={DEFAULT_LIST_PAGE_PAGINATION_LIMIT}
      sort={{ order: 'DESC', field: 'id' }}
      queryOptions={{ meta: { prefetch } }}
    >
      <DatagridConfigurable
        omit={[
          'attributes.company_name',
          'attributes.primary_contact',
          'attributes.phone_number',
          'attributes.over_budget',
          'attributes.mail_cap_status',
          'attributes.billing_type',
          'attributes.payment_type',
          'relationships.registration.data.id',
        ]}
      >
        <TextField source="id" />
        <ImageField
          source="attributes.logo_url"
          sortable={false}
          label="Logo"
          sx={{
            '& img': {
              maxHeight: DEFAULT_LIST_PAGE_IMAGE_MAX_HEIGHT,
              maxWidth: DEFAULT_LIST_PAGE_IMAGE_MAX_WIDTH,
            },
          }}
        />
        <TextField source="attributes.company_name" label="Company Name" />
        <TextField source="attributes.merchant_name" label="Merchant Name" />
        {canListRegions && (
          <ReferenceField
            source="relationships.region.data.id"
            reference="regions"
            emptyText="Record Missing"
            label="Region"
          >
            <TextField source="attributes.name" />
          </ReferenceField>
        )}
        <UrlField source="attributes.website" label="Website" />
        <FunctionField
          label="Merchant Portal Link"
          source="attributes.username"
          render={(record?: Partial<MerchantResource>) => {
            const username = record?.attributes?.username;

            if (!username) {
              return <i>Unknown</i>;
            }

            return (
              <a
                href={`https://merchant.getprice.com.au/Login.aspx?u=${username}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {username}
              </a>
            );
          }}
        />
        {canListMerchantStatuses && (
          <ReferenceField
            source="relationships.merchant_status.data.id"
            reference="merchant_statuses"
            emptyText="Unknown Status"
            label="Status"
          >
            <TextField source="attributes.name" />
          </ReferenceField>
        )}
        {canListAffiliateNetworks && (
          <ReferenceField
            label="Affiliate Network"
            source="relationships.affiliate_network.data.id"
            emptyText="Record missing"
            reference="affiliate_networks"
          >
            <TextField source="attributes.name" />
          </ReferenceField>
        )}
        <NumberField label="CPC (c)" source="attributes.cpc" />
        <TextField label="Primary Contact" source="attributes.primary_contact" />
        <TextField label="Phone Number" source="attributes.phone_number" />
        <EmailField label="Email" source="attributes.email" />
        <NumberField
          source="attributes.monthly_budget"
          label="Monthly Budget"
          options={{ style: 'currency', currency: 'AUD' }}
        />
        <BooleanField source="attributes.over_budget" label="Over Budget?" looseValue />
        <SelectField
          source="attributes.mail_cap_status_code"
          label="Mail Cap Status"
          choices={MAIL_CAP_STATUS_CHOICES}
        />
        <SelectField
          source="attributes.billing_type_code"
          label="Billing Type"
          choices={BILLING_TYPE_CHOICES}
        />
        <SelectField
          source="attributes.payment_type_code"
          label="Payment Type"
          choices={PAYMENT_TYPE_CHOICES}
        />
        <BooleanField
          source="attributes.foreign_company_status_code"
          label="Is Foreign Company?"
          looseValue
        />
        {canListRegistrations && (
          <ReferenceField
            source="relationships.registration.data.id"
            reference="registrations"
            emptyText="Record missing"
            label="Registration"
          />
        )}
      </DatagridConfigurable>
    </List>
  );
}
