import {
  AutocompleteArrayInput,
  DatagridConfigurable,
  List,
  ReferenceArrayInput,
  ReferenceField,
  TextArrayInput,
  TextField,
  TextInput,
  useCanAccess,
} from 'react-admin';
import { ReactElement } from 'react';
import ActionPanel from './ActionPanel';
import { createFilterQuery } from '../../utils';

const filters = [
  <ReferenceArrayInput
    key="merchant_id"
    label="Merchant"
    source="merchant.id"
    reference="merchants"
  >
    <AutocompleteArrayInput
      label="Merchant"
      optionText="attributes.merchant_name"
      filterToQuery={createFilterQuery(['merchant_name'])}
    />
  </ReferenceArrayInput>,
  <ReferenceArrayInput
    key="category_id"
    label="Category"
    source="category.id"
    reference="categories"
  >
    <AutocompleteArrayInput
      label="Category"
      optionText="attributes.name"
      filterToQuery={createFilterQuery()}
    />
  </ReferenceArrayInput>,
  <TextArrayInput
    key="external_category"
    label="External Category Contains"
    source="contains.external_category"
  />,
  <TextArrayInput
    key="external_category_not_contains"
    label="External Category Does Not Contain"
    source="not.contains.external_category"
  />,
  <TextInput
    key="external_category_exact"
    label="External Category Is Exactly"
    source="equals.external_category"
  />,
];

export default function MappingRuleList(): ReactElement {
  const merchantListAccess = useCanAccess({ action: 'list', resource: 'merchants' });
  const categoryListAccess = useCanAccess({ action: 'list', resource: 'categories' });

  const prefetch: string[] = [
    merchantListAccess.canAccess && 'merchant',
    categoryListAccess.canAccess && 'category',
  ].filter((i) => typeof i === 'string');

  return (
    <List
      sort={{ field: 'id', order: 'DESC' }}
      perPage={25}
      filters={filters}
      queryOptions={{ meta: { prefetch } }}
    >
      <DatagridConfigurable expand={<ActionPanel />}>
        <TextField source="id" />
        <ReferenceField
          label="Merchant"
          source="relationships.merchant.data.id"
          reference="merchants"
        >
          <TextField source="attributes.merchant_name" />
        </ReferenceField>
        <TextField label="External Category" source="attributes.external_category" />
        <ReferenceField
          label="Category"
          source="relationships.category.data.id"
          reference="categories"
        >
          <TextField source="attributes.full_name" />
        </ReferenceField>
      </DatagridConfigurable>
    </List>
  );
}
