import {
  AutocompleteArrayInput,
  DatagridConfigurable,
  List,
  NullableBooleanInput,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceField,
  TextArrayInput,
  TextField,
  useCanAccess,
} from 'react-admin';
import { ClickResource } from '../../Entities/Click';
import { DEFAULT_LIST_PAGE_PAGINATION_LIMIT } from '../../utils';
import { ReactElement } from 'react';
import TzDateField from '../../Components/TzDate/TzDateField';
import TruncatedTextField from '../../Components/TruncatedTextField';
import TzDateInput from '../../Components/TzDate/TzDateInput';
import { DateTime } from 'luxon';

const oneWeekAgo = DateTime.now()
  .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  .minus({ weeks: 1 })
  .toJSDate();

const filters = (
  canListCategories: boolean,
  canListMerchants: boolean,
  canListOffers: boolean,
  canListProducts: boolean,
): ReactElement[] =>
  [
    <TzDateInput
      key="filter.date_gte"
      translateTimezone
      label="Click Timestamp After"
      source="greaterOrEqual.timestamp"
      alwaysOn
    />,
    <TzDateInput
      key="filter.date_lte"
      translateTimezone
      label="Click Timestamp Before"
      source="lessOrEqual.timestamp"
      alwaysOn
    />,
    <NumberInput key="filter.id" label="ID" source="equals.id" />,
    <TextArrayInput key="filter.client_ip" label="Client IP" source="contains.client_ip" />,
    <NumberInput key="filter.earnings_lt" label="Earnings Less Than" source="lessThan.earnings" />,
    <NumberInput
      key="filter.earnings_gt"
      label="Earnings Greater Than"
      source="greaterThan.earnings"
    />,
    <TextArrayInput
      key="filter.enhanced_partner_id"
      label="Enhanced Partner IDs"
      source="equals.enhanced_partner_id"
    />,
    <TextArrayInput
      key="filter.exclude_enhanced_partner_id"
      label="Exclude Enhanced Partner IDs"
      source="not.equals.enhanced_partner_id"
    />,
    <NullableBooleanInput
      key="filter.has_referrer"
      label="Has Referrer?"
      source="not.isNull.referrer"
    />,
    <TextArrayInput
      key="filter.referrer_contains"
      label="Referrer Contains"
      source="contains.referrer"
    />,
    <TextArrayInput
      key="filter.referrer_not_contain"
      label="Referrer Does Not Contain"
      source="not.contains.referrer"
    />,
    <NullableBooleanInput
      key="filter.has_request_url"
      label="Has Request URL?"
      source="not.isNull.request_url"
    />,
    <TextArrayInput
      key="filter.request_url_contains"
      label="Request URL Contains"
      source="contains.request_url"
    />,
    <TextArrayInput
      key="filter.request_url_not_contain"
      label="Request URL Does Not Contain"
      source="not.contains.request_url"
    />,
    <TextArrayInput
      key="filter.user_agent_contains"
      label="User Agent Contains"
      source="contains.user_agent"
    />,
    <TextArrayInput
      key="filter.user_agent_equals"
      label="User Agent Equals"
      source="equals.user_agent"
    />,
    <TextArrayInput
      key="filter.user_agent_not_contain"
      label="User Agent Does Not Contain"
      source="not.contains.user_agent"
    />,
    canListCategories && (
      <NullableBooleanInput
        key="filter.category_null"
        label="Has Category?"
        source="not.isNull.category"
      />
    ),
    canListCategories && (
      <ReferenceArrayInput
        label="Categories"
        key="filter.category.id"
        source="equals.category.id"
        reference="categories"
        target="id"
      >
        <AutocompleteArrayInput label="Categories" optionText="attributes.name" />
      </ReferenceArrayInput>
    ),
    canListCategories && (
      <ReferenceArrayInput
        label="Exclude Categories"
        key="filter.not.category"
        source="not.equals.category.id"
        reference="categories"
        target="id"
      >
        <AutocompleteArrayInput label="Exclude Categories" optionText="attributes.name" />
      </ReferenceArrayInput>
    ),
    canListMerchants && (
      <NullableBooleanInput
        key="filter.merchant_null"
        label="Has Merchant?"
        source="not.isNull.merchant"
      />
    ),
    canListMerchants && (
      <ReferenceArrayInput
        label="Merchants"
        key="filter.merchant.id"
        source="equals.merchant.id"
        reference="merchants"
        target="id"
      >
        <AutocompleteArrayInput label="Merchants" optionText="attributes.merchant_name" />
      </ReferenceArrayInput>
    ),
    canListMerchants && (
      <ReferenceArrayInput
        label="Exclude Merchants"
        key="filter.not.merchant"
        source="not.equals.merchant.id"
        reference="merchants"
        target="id"
      >
        <AutocompleteArrayInput label="Exclude Merchants" optionText="attributes.merchant_name" />
      </ReferenceArrayInput>
    ),
    canListOffers && (
      <NullableBooleanInput key="filter.offer_null" label="Has Offer?" source="not.isNull.offer" />
    ),
    canListOffers && (
      <ReferenceArrayInput
        label="Offers"
        key="filter.offer.id"
        source="equals.offer.id"
        reference="offers"
        target="id"
      >
        <AutocompleteArrayInput label="Offers" optionText="attributes.name" />
      </ReferenceArrayInput>
    ),
    canListOffers && (
      <ReferenceArrayInput
        label="Exclude Offers"
        key="filter.not.offer"
        source="not.equals.offer.id"
        reference="offers"
        target="id"
      >
        <AutocompleteArrayInput label="Exclude Offers" optionText="attributes.name" />
      </ReferenceArrayInput>
    ),
    canListProducts && (
      <NullableBooleanInput
        key="filter.product_null"
        label="Has Product?"
        source="not.isNull.product"
      />
    ),
    canListProducts && (
      <ReferenceArrayInput
        label="Products"
        key="filter.product.id"
        source="equals.product.id"
        reference="products"
        target="id"
      >
        <AutocompleteArrayInput label="Products" optionText="attributes.name" />
      </ReferenceArrayInput>
    ),
    canListProducts && (
      <ReferenceArrayInput
        label="Exclude Products"
        key="filter.not.product"
        source="not.equals.product.id"
        reference="products"
        target="id"
      >
        <AutocompleteArrayInput label="Exclude Products" optionText="attributes.name" />
      </ReferenceArrayInput>
    ),
  ].filter((value) => typeof value !== 'boolean');

export default function ClickList(): ReactElement {
  const categoryAccess = useCanAccess({ resource: 'categories', action: 'list' });
  const merchantAccess = useCanAccess({ resource: 'merchants', action: 'list' });
  const offerAccess = useCanAccess({ resource: 'offers', action: 'list' });
  const productAccess = useCanAccess({ resource: 'products', action: 'list' });

  const canListCategories = categoryAccess.canAccess ?? false;
  const canListMerchants = merchantAccess.canAccess ?? false;
  const canListOffers = offerAccess.canAccess ?? false;
  const canListProducts = productAccess.canAccess ?? false;

  const prefetch: string[] = [
    canListCategories && 'category',
    canListMerchants && 'merchant',
    canListOffers && 'offer',
    canListProducts && 'product',
  ].filter((v) => typeof v === 'string');

  return (
    <List<ClickResource>
      perPage={DEFAULT_LIST_PAGE_PAGINATION_LIMIT}
      filterDefaultValues={{ greaterOrEqual: { timestamp: oneWeekAgo } }}
      filters={filters(canListCategories, canListMerchants, canListOffers, canListProducts)}
      queryOptions={{ meta: { prefetch } }}
      sort={{ order: 'DESC', field: 'id' }}
    >
      <DatagridConfigurable
        omit={['attributes.enhanced_partner_id', 'relationships.offer.data.id']}
      >
        <TextField label="ID" source="id" />
        <TextField label="Client IP" source="attributes.client_ip" />
        <NumberField label="Earnings (AUD cents)" source="attributes.earnings" />
        <TextField label="Enhanced Partner ID" source="attributes.enhanced_partner_id" />
        <TextField label="Referrer" source="attributes.referrer" />
        <TextField label="Request URL" source="attributes.request_url" />
        <TzDateField showTime label="Timestamp" source="attributes.timestamp" />
        <TruncatedTextField length={50} label="User Agent" source="attributes.user_agent" />
        {canListCategories && (
          <ReferenceField
            label="Category"
            source="relationships.category.data.id"
            emptyText="Record missing"
            reference="categories"
          >
            <TextField source="attributes.name" />
          </ReferenceField>
        )}
        {canListMerchants && (
          <ReferenceField
            label="Merchant"
            source="relationships.merchant.data.id"
            emptyText="Record missing"
            reference="merchants"
          >
            <TextField source="attributes.merchant_name" />
          </ReferenceField>
        )}
        {canListOffers && (
          <ReferenceField
            label="Offer"
            source="relationships.offer.data.id"
            emptyText="Record missing"
            reference="offers"
          >
            <TextField source="attributes.name" />
          </ReferenceField>
        )}
        {canListProducts && (
          <ReferenceField
            label="Product"
            source="relationships.product.data.id"
            emptyText="Record missing"
            reference="products"
          >
            <TextField source="attributes.name" />
          </ReferenceField>
        )}
      </DatagridConfigurable>
    </List>
  );
}
