import {
  AutocompleteArrayInput,
  BooleanField,
  DatagridConfigurable,
  FunctionField,
  ImageField,
  List,
  NullableBooleanInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceField,
  TextField,
  TextInput,
  useCanAccess,
} from 'react-admin';
import { ProductResource } from '../../Entities/Product';
import TruncatedRichTextField from '../../Components/TruncatedRichTextField';
import TzDateInput from '../../Components/TzDate/TzDateInput';
import {
  createFilterQuery,
  DEFAULT_LIST_PAGE_IMAGE_MAX_HEIGHT,
  DEFAULT_LIST_PAGE_IMAGE_MAX_WIDTH,
  DEFAULT_LIST_PAGE_PAGINATION_LIMIT,
} from '../../utils';
import { ReactElement } from 'react';

const filters = (canListCategories: boolean): ReactElement[] =>
  [
    <NumberInput alwaysOn key="filter.id" label="ID" source="equals.id" />,
    <TextInput alwaysOn key="filter.name" label="Product Name" source="contains.name" />,
    <TextInput key="filter.description" label="Description" source="contains.description" />,
    <TextInput key="filter.manufacturer" label="Manufacturer" source="contains.manufacturer" />,
    <TextInput key="filter.keywords" label="Keywords" source="contains.keywords" />,
    canListCategories && (
      <ReferenceArrayInput
        key="filter.category"
        label="Category"
        source="equals.category.id"
        reference="categories"
      >
        <AutocompleteArrayInput
          label="Category"
          optionText="attributes.full_name"
          filterToQuery={createFilterQuery()}
        />
      </ReferenceArrayInput>
    ),
    <NullableBooleanInput
      key="filter.is_prioritized"
      label="Is Prioritized?"
      source="equals.is_prioritized"
    />,
    <NullableBooleanInput key="filter.status" label="Is Active?" source="equals.status" />,
    <NumberInput
      key="filter.shop_count_gt"
      label="Shop count: More than"
      source="greaterThan.shop_count"
    />,
    <NumberInput
      key="filter.shop_count_lt"
      label="Shop count: Less than"
      source="lessThan.shop_count"
    />,
    <NumberInput
      key="filter.views_count_gt"
      label="Views count: More than"
      source="greaterThan.views_count"
    />,
    <NumberInput
      key="filter.views_count_lt"
      label="Views count: Less than"
      source="lessThan.views_count"
    />,
    <TzDateInput key="filter.edited_gt" label="Edited After" source="greaterThan.edited" />,
    <TzDateInput key="filter.edited_lt" label="Edited Before" source="lessThan.edited" />,
  ].filter((value) => typeof value !== 'boolean');

export default function MerchantList(): ReactElement {
  const categoryAccess = useCanAccess({ resource: 'categories', action: 'list' });
  const canListCategories = categoryAccess.canAccess ?? false;

  const prefetch: string[] = [canListCategories && 'category'].filter((i) => typeof i === 'string');

  return (
    <List<ProductResource>
      perPage={DEFAULT_LIST_PAGE_PAGINATION_LIMIT}
      filters={filters(canListCategories)}
      queryOptions={{ meta: { prefetch } }}
      sort={{ order: 'DESC', field: 'id' }}
    >
      <DatagridConfigurable>
        <TextField label="ID" source="id" />
        <ImageField
          source="attributes.image_url"
          sortable={false}
          label="Image"
          sx={{
            '& img': {
              maxHeight: DEFAULT_LIST_PAGE_IMAGE_MAX_HEIGHT,
              maxWidth: DEFAULT_LIST_PAGE_IMAGE_MAX_WIDTH,
            },
          }}
        />
        <FunctionField
          label="Product Name"
          render={(record?: Partial<ProductResource>) => {
            const name = record?.attributes?.name;
            const sluggifiedName = record?.attributes?.sluggified_name;

            if (!name || !sluggifiedName) {
              return <i>Unknown</i>;
            }

            return (
              <a
                href={`https://www.getprice.com.au/product/${sluggifiedName}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {name}
              </a>
            );
          }}
        />
        {canListCategories && (
          <ReferenceField
            label="Category"
            source="relationships.category.data.id"
            reference="categories"
          >
            <TextField source="attributes.full_name" />
          </ReferenceField>
        )}
        <TextField source="attributes.keywords" label="Keywords" />
        <TextField source="attributes.manufacturer" label="Manufacturer" />
        <TruncatedRichTextField source="attributes.description" label="Description" />
        <BooleanField source="attributes.status" label="Is Active?" />
        <BooleanField source="attributes.is_prioritized" label="Is Prioritized?" />
        <TextField source="attributes.shop_count" label="Shop Count" />
        <TextField source="attributes.views_count" label="Views Count" />
      </DatagridConfigurable>
    </List>
  );
}
