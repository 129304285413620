import { DeleteParams, DeleteResult, fetchUtils, Identifier, RaRecord } from 'react-admin';
import { defaultHeaders } from './utils';
import getOne from './getOne';
import { JsonApiResource } from './JsonApiTypes';

export default function deleteFn(apiUrl: string, httpClient = fetchUtils.fetchJson) {
  const getOneFn = getOne(apiUrl, httpClient);

  return async <T extends RaRecord<Identifier>>(
    resource: string,
    params: DeleteParams<T>,
  ): Promise<DeleteResult<T & JsonApiResource>> => {
    const existingRecord = (await getOneFn(resource, params)).data as T & JsonApiResource;

    const url = `${apiUrl}/${resource}/${params.id}`;

    await httpClient(url, {
      method: 'DELETE',
      headers: defaultHeaders,
    });

    return { data: existingRecord };
  };
}
